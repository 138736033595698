import React from 'react';

const SampleLocationBoxes = ({ sampleLocations, setSelectedLocation, selectedLocation, setErrors2 }) => {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
      {sampleLocations.map((locationData, index) => {
        const { location, count, capacidad } = locationData;
        const capacityPercentage = ((count / capacidad) * 100).toFixed(2);


        const isFullCapacity = capacityPercentage >= 100;

        function handleClick() {
          if (!isFullCapacity) {
            setSelectedLocation(`${location}|${capacidad}`);
            setErrors2({ descripcion: ''});
          }
        }

        return (
          <div 
            onClick={() => handleClick()}
            key={index} 
            style={{
              border: '1px solid #ccc', 
              padding: '10px', 
              width: '150px', 
              borderRadius: '5px', 
              backgroundColor: isFullCapacity ? '#ffcccb' : (selectedLocation === `${location}|${capacidad}` ? '#add8e6' : '#f9f9f9'),
              boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              cursor: 'pointer',
            }}
          >
            <h4 style={{ marginBottom: '5px' }}>Ubicación</h4>
            <div dangerouslySetInnerHTML={{ __html: location }} />
            <p>Muestras: {count}</p>
            <p>Capacidad: {capacidad}</p>
            <p>Capacidad utilizada: {capacityPercentage}%</p>
          </div>
        );
      })}
    </div>
  );
};

export default SampleLocationBoxes;
