import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import './css/parametrizacion.css';
import {
  selectors,
  sendReportRequest
} from '../redux/reducers/sample';
import ContentWrapper from './commons/ContentWrapper';
import './commons/css/lines.css';
import { useNavigate } from 'react-router-dom';
import Modal from './commons/Modal';
import ConfirmationModal from './commons/ConfirmationModal';
import 'react-quill/dist/quill.snow.css';
import { selectors as sessionSelectors} from "../redux/reducers/sessionReducer"
import { findReportDescriptionForSample } from '../utils/sample'
import PdfViewer from './commons/PdfViewer';

const EnviarInforme = ({ loading, error, modalLoading, session, selectedSample }) => {
  

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initForm = {
    descripcion: '',
  }

  const [showModal, setShowModal] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [formData, setFormData] = useState(initForm);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModalConfirmation(true);
  };

  const confirmInforme = () => {
    setShowModalConfirmation(false);
    setShowModal(true);

    const informeData = {
      doneBy: session.username,
      protocolNumber: selectedSample.protocolNumber,
    };

    dispatch(sendReportRequest(informeData));
  };

  const confirmOperation = () => {
    setFormData(initForm);
    setShowModal(false);
    navigate('/listaInformesListos');
  };

  const pdf = findReportDescriptionForSample(selectedSample);

  return (
    <ContentWrapper title="Enviar informe final a paciente" loading={loading} error={error}>
        <form onSubmit={handleSubmit} className="informe-form">

     
          <h3>Vista previa del informe a enviar</h3>

        <div style={{ width: '120%', height: '120%', overflow: 'auto', border: '1px', margin: '0 auto' }}>
                    <PdfViewer base64Data={pdf} scale={2} ismacroscopy={selectedSample.type == "HISTOLOGIC" && selectedSample.subtype == "REGULAR"} />
        </div>

          <button type="submit" className="submit-button-param">Enviar</button>
          </form>

          {showModal && (
            <Modal
              modalLoading={modalLoading}
              error={error}
              onClose={() => confirmOperation()}
            />
          )}
          {showModalConfirmation && (
            <ConfirmationModal
              message={`Confirma el envío del informe final?`}
              onConfirm={() => confirmInforme()}
              onCancel={() => setShowModalConfirmation(false)}
            />
          )}

    </ContentWrapper>
  );
};


const mapStateToProps = (state) => ({
    modalLoading: selectors.modalLoading(state),
    loading: selectors.loading(state),
    error: selectors.error(state),
    session: sessionSelectors.session(state),
    selectedSample: selectors.selectedSample(state),
});

export default connect(mapStateToProps)(EnviarInforme);
