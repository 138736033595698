import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import Modal from '../commons/Modal';
import { selectors } from '../../redux/reducers/professional';
import sample, { setSampleOwnerRequest } from '../../redux/reducers/sample';
import ConfirmationModal from '../commons/ConfirmationModal';
import '../commons/css/lines.css';
import { useNavigate } from 'react-router-dom';
import { selectors as sampleSelectors } from '../../redux/reducers/sample';
import { selectors as sessionSelectors} from "../../redux/reducers/sessionReducer";
import { selectors as profesionalSelectors } from '../../redux/reducers/professional';

const ProfessionalList = ({ professionals, error, modalLoading, selectedSample, session }) => {
  const [selectedProf, setSelectedProf] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const confirmOperation = () => {
    setShowModal(false);

    // TODO: redireccionar segun el paso
    // Redirecciono al listao de muestras segun el tipo
    if (selectedSample.type == 'HISTOLOGIC')
      navigate('/asignarMedico');
    else
      navigate('/asignarTecnico');
  };

  const confirmSelection = () => {
    setShowModalConfirmation(false);
    let reqAssignment = {
      assignedBy: session.username,
      assignedTo: selectedProf.username,
      assignationType: selectedProf.type === "DOCTOR" ? 'DOCTOR' :  'TECHNICIAN', // si el profesional es doctor, caso contrario es tecnico (cito o histo)
      protocolNumber: selectedSample.protocolNumber,
    };

    dispatch(setSampleOwnerRequest(reqAssignment));
    setShowModal(true);
  //  setSelectedProf(null);
  };

  const handleSelectProfesional = (professional) => {
    setSelectedProf(professional);
    setShowModalConfirmation(true);
  };

  const buildConfirmText = () => {
    return "Se asigno muestra a " +  selectedProf.username + ".";
  };

  return (
    <div className="sample-list-container">
      <div className="header-row">
        <div className="cell">Profesional</div>
        <div className="cell">Acción</div>
      </div>
      {error && error.show && (
        <p className="error-message">No se pudo cargar la información de profesionales</p>
      )}
      {professionals  && professionals.map(professional => (
        <div key={professional.id} className="row">
          <div className="cell">{professional.username}</div>
          <div className="cell">
              <button
                className="action-button"
                onClick={() => handleSelectProfesional(professional)}
              >
               Asignar
              </button>
          </div>
        </div>
      ))}
      {!professionals && 
        <div>Sin profesionales disponibles</div>
      }
      {showModal && (
        <Modal
          modalLoading={modalLoading}
          error={error}
          text={buildConfirmText()}
          onClose={() => confirmOperation()}
        />
      )}
      {showModalConfirmation && (
        <ConfirmationModal
          message={`¿Confirma la seleccion del profesional ${selectedProf.username}?`}
          onConfirm={() => confirmSelection()}
          onCancel={() => setShowModalConfirmation(false)}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: selectors.loading(state),
  modalLoading: selectors.modalLoading(state) || sampleSelectors.modalLoading(state),
  error: selectors.error(state),
  selectedSample: sampleSelectors.selectedSample(state),
  session: sessionSelectors.session(state),
  professionals: profesionalSelectors.professionals(state),
});

export default connect(mapStateToProps)(ProfessionalList);
