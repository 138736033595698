import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import '../css/parametrizacion.css';
import ContentWrapper from '../commons/ContentWrapper';
import '../commons/css/lines.css';
import ProfessionalList from './ProfessionalList';
import { listProfessionalRequest, selectors } from '../../redux/reducers/professional';
import { selectors as sampleSelectors } from '../../redux/reducers/sample';
import './css/pagination.css';
import { useSearchParams } from 'react-router-dom';
import SampleDetail from '../SampleDetail';

const DoctorSelector = ({ loading, error, professionals, selectedSample }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const step = searchParams.get('step');

  const [filters, setFilters] = useState({
    patientEmail: null,
    status: null,
    from: null,
    to: null,
  });

  const [pagination, setPagination] = useState({
    limit: 10,
    page: 1,
  });

  useEffect(() => {
    const query = {
      ...filters,
      limit: pagination.limit,
      offset: (pagination.page - 1) * pagination.limit,
      type: selectedSample.type,
      step,
    };
    dispatch(listProfessionalRequest(query));
  }, [dispatch, pagination]);

  const handleSearchClick = () => {
    if (filters.patientEmail === "") filters.patientEmail = null;
    if (filters.status === "") filters.status = null;

    setPagination((prevPagination) => ({
      ...prevPagination,
      page: 1,
    }));

    const query = {
      ...filters,
      limit: pagination.limit,
      offset: 0,
      sampleType: selectedSample.type,
    };
    dispatch(listProfessionalRequest(query));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handlePageChange = (newPage) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: newPage,
    }));
  };


  return (
    <ContentWrapper title="Selección de profesional" loading={loading} error={error}>

      <SampleDetail selectedSample={selectedSample}>
      </SampleDetail>

      {professionals && professionals.length === 0 ? (
        <p>No se encontraron profesionales disponibles en este momento.</p>
      ) : (
        <>
          <ProfessionalList professionals={professionals} />
          <div className="pagination-controls">
            <button
              className="pagination-button"
              disabled={pagination.page === 1}
              onClick={() => handlePageChange(pagination.page - 1)}
            >
              ⟵ Anterior
            </button>
            <span className="pagination-page">Página {pagination.page}</span>
            <button
              className="pagination-button"
              disabled={professionals && professionals.length < pagination.limit}
              onClick={() => handlePageChange(pagination.page + 1)}
            >
              Siguiente ⟶
            </button>
          </div>
        </>
      )}
    </ContentWrapper>
  );
};

const mapStateToProps = (state) => ({
  professionals: selectors.professionals(state),
  loading: selectors.loading(state),
  error: selectors.error(state),
  selectedSample: sampleSelectors.selectedSample(state),
});

export default connect(mapStateToProps)(DoctorSelector);
