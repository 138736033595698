import React, { useState } from 'react';
import { connect } from 'react-redux';
import { selectors } from '../../redux/reducers/sample';
import StateDiagram from "../commons/StateDiagram";
import { translate } from '../../utils/translate';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import { setSelectedSample } from '../../redux/reducers/sample';
import { formatDate } from '../../utils/dates';

const SampleList = ({ samples, error, actions, readOnly }) => {

  const [expandedSample, setExpandedSample] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleToggleDetails = (sample) => {
    setExpandedSample(sample);
  };

  const handleTraceReport = (sample) => {
    dispatch(setSelectedSample(sample));
    navigate('/trazabilidad');
  };

  const closeModal = () => {
    setExpandedSample(null);
  };

  const renderModal = () => {
    if (!expandedSample) return null;
    return (
      <div className="modal-overlay">
        <div className="modal-diagram">
          <StateDiagram data={expandedSample} />
          <div style={{ display: 'flex',                 paddingLeft: '0px'  , marginTop: '20px' }}>
            <button className="close-button" onClick={closeModal}>Cerrar</button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="sample-list-container">
      {renderModal()}

      <div className="header-row">
      <div className="cell">Nro Protocolo</div>
        <div  style={{ width: '100px',  paddingRight: '40px' }}>DNI Paciente</div>
        <div  style={{ width: '200px' }}>Nombre y Apellido</div>
        <div className="cell">Fecha ingreso</div>
        {!readOnly && <div className="cell">Tipo</div>}
        <div className="cell">Subtipo</div>
        <div className="cell">Estado</div>
        <div className="cell">Responsable</div>
        <div className="cell">Acción</div>
        <div className="cell"></div>
      </div>

      {error && error.show && (
        <p className="error-message">No se pudo cargar la información de muestras</p>
      )}

      {samples && samples.map(sample => (
        <div key={sample.id} className="row">
          <div className="cell">{sample.protocolNumber}</div>
          <div  style={{ width: '100px', paddingRight: '40px' }}>{sample.patientDNI}</div>
          <div  style={{ width: '200px' }}>{sample.patientName}</div>
          <div className="cell">{formatDate(sample.creationDate)}</div>
          {!readOnly && <div className="cell">{translate(sample.type)}</div>}
          <div className="cell">{translate(sample.subtype)}</div>
          <div className="cell">{translate(sample.status)}</div>
          <div className="cell">{sample.assignedTo ? sample.assignedTo : "Sin asignar"}</div>



          <div className="cell" style={{ width: '1px', display: 'flex', gap: '1px' }}>
          <button className="detail-button" onClick={() => handleToggleDetails(sample)}>
              Detalle
            </button>
            {sample.status == "DONE" && <button className="detail-button" onClick={() => handleTraceReport(sample)}>
              Informe
            </button>
            }
          </div>

          {actions && actions.size != 0 && (
          <div className="cell">

            {actions && actions.map(action => (
              <button
                key={action.name}
                className="action-button"
                onClick={() => action.function(sample)}
              >
                {action.name}
              </button>
            ))}

          </div>
          )}

        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: selectors.loading(state),
  modalLoading: selectors.modalLoading(state),
  error: selectors.error(state),
});

export default connect(mapStateToProps)(SampleList);
