import React, {useEffect, useState} from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import { selectors as sessionSelector } from "../redux/reducers/sessionReducer";
import { clearSession } from '../redux/reducers/sessionReducer';
import { selectors as authSelector, logout } from "../redux/reducers/auth";
import { Link } from 'react-router-dom';
import { FaUserCog, FaSignOutAlt } from 'react-icons/fa';
import { getDescriptionById } from '../constants';
import WorkChart from './dashboards/WorkChart';
import { selectors as metricsSelectors, getStatsRequest } from "../redux/reducers/metrics";
import { listDashRequest, selectors  as sampleSelectors, setNotificationAssign } from '../redux/reducers/sample';
import { getSystemData } from '../redux/reducers/system';
import { permissionExists } from '../security/permissions'
import { useWebSocket } from './WebSocketProvider';
import CardResult from './CardResult';
import SamplesBySubtypeAndDayChart from './dashboards/SamplesBySubtypeAndDayChart';
import Footer from "./Footer";

import './css/dashboard.css';

function Dashboard({ session, auth, stats, dashresult, notification_assign }) {
  const dispatch = useDispatch();

  const [notification, setNotification] = useState(null);
  const socket1 = useWebSocket();

  const { data } = useSelector(state => state.systemReducer);


  const handleLogout = () => {
    dispatch(clearSession());
    dispatch(logout());
  };

  useEffect(() => {
    dispatch(listDashRequest(session));
    dispatch(getStatsRequest());
    dispatch(getSystemData());
  }, [dispatch]);

  useEffect(() => {

    if (notification_assign && notification_assign.operationType === "assignation" && notification_assign.assignedTo == session.username){
      setNotification(`Nueva asignacion: ${notification_assign.protocolNumber} hecha por ${notification_assign.assignedBy}`);
      dispatch(listDashRequest());
      setTimeout(() => {
        setNotification(null);
        dispatch(setNotificationAssign(undefined));
      }, 5000);

    }

    if (!socket1) return;

  }, [notification_assign]);


  const renderMenu = (functionalities) => {
    return (
      <ul className="menu-list">
        {functionalities.length === 0 ? (
          <li className="no-functions">Usuario sin funcionalidades</li>
        ) : (
          functionalities
            .filter(func => !func.nomenu)
            .map((func, index) => (
              <li key={index} className="menu-item">
                <Link to={func.link} className="menu-link">
                  <span className="menu-icon">{func.icon}</span>
                  {func.name}
                </Link>
              </li>
            ))
        )}
      </ul>
    );
  };


  return (
    <div className="dashboard-container">
      <aside className="sidebar">
        <div className="profile-section">
          <div className="profile-pic">
            <FaUserCog />
          </div>
          <div className="profile-info">
            <div className="profile-name">Hola, {session.username}</div>
            <div className="profile-role">{getDescriptionById(session.userType)}</div>
            <button onClick={handleLogout} className="logout-button">
              <FaSignOutAlt /> Cerrar sesión
            </button>
          </div>
        </div>
        {auth && renderMenu(auth.data.functions)}
      </aside>
      <div className="content-container">
        <header className="header">
          <h1 className="header-title">Panel de control</h1>
        </header>
        <main className="dashboard-content">


          <div className="stats-container">

          {notification && (
            <div className="notification_assign">
              {notification}
            </div>
          )}

            {permissionExists('TECHNIC_ASSIGNATION', auth.data.functions) &&
              <CardResult dashresult={dashresult} field={dashresult ? dashresult.assignTechnician : null} label={'Trabajo pendiente de asignación a técnico'} link={"/asignarTecnico"} icon={'pending'} />
            }

            {permissionExists('DOCTOR_ASSIGNATION', auth.data.functions) &&
              <CardResult dashresult={dashresult} field={dashresult ? dashresult.assignDoctor : null} label={'Trabajo pendiente de asignación a médico'} link={"/asignarMedico"} icon={'pending'} />
            }

            {permissionExists('MACROSCOPY', auth.data.functions) &&
              <CardResult dashresult={dashresult} field={dashresult ? dashresult.macroscopy: null} label={'En macroscopía'} link={"/macroscopia"} icon={'in-progress'} />
            }

            {permissionExists('DIAGNOSIS', auth.data.functions) &&
              <CardResult dashresult={dashresult} field={dashresult ? dashresult.diagnosticReady: null} label={'Listo para diagnóstico'} link={"/diagnostico"} icon={'completed'} />
            }

            {(permissionExists('HISTO_LAB_PROCESS', auth.data.functions) || permissionExists('CITO_LAB_PROCESS', auth.data.functions)) &&
              <CardResult dashresult={dashresult} field={dashresult ? dashresult.histoProcessing: null} label={'En proceso de laboratorio'} icon={'in-progress'} />
            }

            {permissionExists('FINAL_REPORT', auth.data.functions) &&
              <CardResult dashresult={dashresult} field={dashresult ? dashresult.reportReady: null} label={'Listo para informe'} link={"/informe"} icon={'completed'} />
            }

            {permissionExists('SEND_FINAL_REPORT_EMAIL', auth.data.functions) &&
              <CardResult dashresult={dashresult} field={dashresult ? dashresult.sendReportReady: null} label={'Informe listo para enviar'} link={"/listaInformesListos"} icon={'completed'} />
            }

            {permissionExists('ARCHIVE_SAMPLES', auth.data.functions) &&
              <CardResult dashresult={dashresult} field={dashresult ? dashresult.archiveSample: null} label={'Listo para archivar'} link={"/ubicacionMuestra"} icon={'completed'} />
            }


          </div>
          <div className="charts-section">
            <div className="chart-placeholder">
            {stats && <>
              <SamplesBySubtypeAndDayChart data={stats} />
                    </>
                }
            </div>
          </div>
          <div style={{ marginTop: '80px' }}>     
            SiTRAPT - {data.description} - versión {data.version}
            </div>
        </main>
    
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  session: sessionSelector.session(state),
  auth: authSelector.login(state),
  stats: metricsSelectors.stats(state),
  loading: sampleSelectors.loading(state),
  socket: sessionSelector.socket(state),
  dashdata: sampleSelectors.dashdata(state),
  dashresult: sampleSelectors.dashresult(state),
  notification_assign: sampleSelectors.notification_assign(state),
});

export default connect(mapStateToProps)(Dashboard);
