import React, { useState, useRef } from 'react';
import { useDispatch, connect } from 'react-redux';
import './css/informe.css';
import { selectors } from '../redux/reducers/sample';
import ContentWrapper from './commons/ContentWrapper';
import Modal from './commons/Modal';
import ConfirmationModal from './commons/ConfirmationModal';
import { generateInformeRequest } from '../redux/reducers/sample';
import 'react-quill/dist/quill.snow.css';
import { selectors as sessionSelectors } from "../redux/reducers/sessionReducer";
import { useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import PdfViewer from './commons/PdfViewer';
import { findDiagnosisDescriptionForSample, getUsedTechnicsForHistology, getUsedTechnicsForCitology, getDoneByForMacroscopyStep, getMacroscopyDescription } from '../utils/sample'

const Informe = ({ loading, error, modalLoading, session, selectedSample }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [pdf, setPDF] = useState(null);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [formData, setFormData] = useState({
    descripcion: '',
    paciente: '',
    medico: '',
    protocolo: '',
    fecha: '',
    servicio: '',
    material: '',
    macroscopica: '',
    diagnostico: '',
  });

  const navigate = useNavigate();

  const formRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModalConfirmation(true);
  };

  const confirmInforme = () => {
    setShowModalConfirmation(false);
    setShowModal(true);

    let formHTML = null;
    if (formRef.current)
    formHTML = formRef.current.innerHTML;



     html2canvas(formRef.current).then((canvas) => {
      const pdf = new jsPDF();

      const imgData = canvas.toDataURL('image/jpeg');
      const imgWidth = 170;
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, 'JPEG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'JPEG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      const reportPDF = pdf.output('datauristring');
      const informeData = {
        doneBy: session.username,
        protocolNumber: selectedSample.protocolNumber,
        description: reportPDF.split(",")[1],
        finish: true,
      };


    //  setPDF(reportPDF.split(",")[1])
    //  console.log(reportPDF.split(",")[1]);
      dispatch(generateInformeRequest(informeData));
    });
  };


  const confirmOperation = () => {
    setFormData({
      descripcion: '',
      paciente: '',
      medico: '',
      protocolo: '',
      fecha: '',
      servicio: '',
      material: '',
      macroscopica: '',
      diagnostico: '',
    });
    setShowModal(false);
     navigate('/informe');
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-AR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  return (
    <ContentWrapper title="Ingresar informe final" loading={loading} error={error}>
      <form onSubmit={handleSubmit} className="informe-form">
      <div ref={formRef}>
        <div className="header-section">
          <div>
          <p><strong>HOSPITAL DIEGO E. THOMPSON</strong></p>
<p><strong>SERVICIO DE ANATOMIA PATOLOGICA</strong></p>
            <p>
              Avellaneda 33 - San Martin - Pcia. Buenos Aires
            </p>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
            <div>
              Paciente:
              <span style={{ marginLeft: "10px" }}>{selectedSample.patientName}</span>
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              Protocolo:
              <span style={{ marginLeft: "10px" , marginRight: "200px"}}>{selectedSample.protocolNumber}</span>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
            <div>
              Médico:
              <span style={{ marginLeft: "10px" }}>{selectedSample.requestingDoctorData.name}</span>
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              Fecha:
              <span style={{ marginLeft: "10px", marginRight: "200px" }}>{formatDate(selectedSample.creationDate)}</span>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
            <div>
              Historia clínica:
              <span style={{ marginLeft: "10px" }}>{selectedSample.clinicHistoryNumber}</span>
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              Servicio:
              <span style={{ marginLeft: "10px" , marginRight: "200px"}}>{selectedSample.requestingDoctorData.specialty}</span>
            </div>
          </div>
        </div>

        <div className="section">
        <p> <span style={{ textDecoration: 'underline' }}>Material remitido:</span></p>
          <span style={{ width: "390px" }}>{selectedSample.submittedMaterialData.description}</span>
        </div>

        <div className="section">
          <p> <span style={{ textDecoration: 'underline' }}>Procedimiento técnico:</span></p>
          {selectedSample.type === "HISTOLOGIC" && (
            <>
              <span style={{ width: "390px" }} />
              <span>{getUsedTechnicsForHistology(selectedSample).join(" - ")}</span>
            </>
          )}
          {selectedSample.type === "CITOLOGIC" && (
            <>
              <span style={{ width: "390px" }} />
              <span>{getUsedTechnicsForCitology(selectedSample).join(" - ")}</span>
            </>
          )}
        </div>

        {selectedSample.subtype === "REGULAR" && (
          <div className="section">
            <p> <span style={{ textDecoration: 'underline' }}>Macroscopía:</span></p>
						{getMacroscopyDescription(selectedSample)}
            <p>Realizado por: {getDoneByForMacroscopyStep(selectedSample)}</p>
          </div>
        )}

        <div className="section">
          <p> <span style={{ textDecoration: 'underline' }}>Diagnóstico:</span></p>
          <span>{findDiagnosisDescriptionForSample(selectedSample)}</span>
        </div>

        <div className="section">
          <p> <span style={{ textDecoration: 'underline' }}>Observaciones:</span></p>
          <textarea name="observaciones" value={formData.observaciones} onChange={handleInputChange} placeholder="" style={{ width: "800px" }} />
        </div>

        </div>
        {pdf && (
        <div style={{ width: '120%', height: '120%', overflow: 'auto', border: '1px', margin: '0 auto' }}>
                    <PdfViewer base64Data={pdf} scale={2} ismacroscopy={selectedSample.type == "HISTOLOGIC" && selectedSample.subtype == "REGULAR"} />
        </div>
 )}
        <button type="submit" className="submit-button">Confirmar</button>

        {showModal && (
          <Modal modalLoading={modalLoading} error={error} onClose={confirmOperation} />
        )}
        {showModalConfirmation && (
          <ConfirmationModal
            message="¿Confirma la creación del informe final?"
            onConfirm={confirmInforme}
            onCancel={() => setShowModalConfirmation(false)}
          />
        )}
      </form>
    </ContentWrapper>
  );


};

const mapStateToProps = (state) => ({
  modalLoading: selectors.modalLoading(state),
  loading: selectors.loading(state),
  error: selectors.error(state),
  session: sessionSelectors.session(state),
  selectedSample: selectors.selectedSample(state),
});

export default connect(mapStateToProps)(Informe);
