import React, { useMemo, useState, useEffect } from "react";
import ReactFlow, { Controls, Background } from "reactflow";
import 'reactflow/dist/style.css';
import { connect, useDispatch } from 'react-redux';
import { selectors, refreshSample, setSampleUpdate } from '../../redux/reducers/sample';
import { selectors as sessionSelector } from "../../redux/reducers/sessionReducer";
import { translate } from '../../utils/translate';
import SampleDetailExtended from '../SampleDetailExtended'
import { FaLightbulb } from 'react-icons/fa';
import ReferencesModalDiagram from "./ReferencesModalDiagram";

const calculateTimeDifference = (start, end) => {
  if (!end) return "En progreso";
  const startDate = new Date(start);
  const endDate = new Date(end);
  const diffMilliseconds = endDate - startDate;

  const diffHours = Math.floor(diffMilliseconds / (1000 * 60 * 60));
  const diffMinutes = Math.floor((diffMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
  const diffSeconds = Math.floor((diffMilliseconds % (1000 * 60)) / 1000);

  return `${diffHours} horas, ${diffMinutes} minutos, ${diffSeconds} segundos`;
};

const StateDiagram = ({ data, sampleUpdate, session }) => {
  const [hoveredNode, setHoveredNode] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalRefOpen, setIsModalRefOpen] = useState(false);
  const [subStepsData, setSubStepsData] = useState(null);
  const [selectedCassette, setSelectedCassette] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const [usedSample, setUsedSample] = useState(data);

  const dispatch = useDispatch();


  const nodes = useMemo(() =>
    usedSample.steps.map((step, index) => ({
      id: `${index}`,
      position: { x: 250 * (index % 2), y: index * 100 },
      data: {
        label: `${translate(step.name)} (${translate(step.status)})`
      },
      style: {
        background: step.status === "DONE" ? "#a0f0a0" :
                    step.status === "IN_PROGRESS" ? "#f0a000" :
                    "#f0a0a0",
        color: "#000",
        border: "1px solid #555",
        borderRadius: "5px",
        padding: "10px",
        cursor: step.name === "HISTOLOGY_PROCESS" ? "pointer" : "default",
      }
    }))
  , [usedSample.steps]);

  const edges = useMemo(() =>
    usedSample.steps.slice(0, -1).map((step, index) => ({
      id: `e${index}-${index + 1}`,
      source: `${index}`,
      target: `${index + 1}`,
      type: "smoothstep",
      animated: true,
      style: { stroke: "#555" },
    }))
  , [usedSample.steps]);

  const handleNodeMouseEnter = (event, node) => {
    const step = usedSample.steps[node.id];
    if (step.status === "DONE" && step.startTimestamp && step.endTimestamp) {
      const timeDiff = calculateTimeDifference(step.startTimestamp, step.endTimestamp);
      setHoveredNode({ id: node.id, timeDiff , doneBy: step.doneBy, assignedTo : step.assignedTo, assignedBy: step.assignedBy, start: step.startTimestamp, end: step.endTimestamp });
    }
  };

  const handleNodeMouseLeave = () => {
    setHoveredNode(null);
  };

  const handleNodeClick = (event, node) => {
    const step = usedSample.steps[node.id];
    if (step.name === "HISTOLOGY_PROCESS" && step.status != "TODO") {
      // Por defecto selecciona el primer casete
      const firstCassette = step.cassettes[0];
      setSelectedCassette(firstCassette.cassetteId);
      updateSubSteps(step.cassettes[0]);
      setIsModalOpen(true);
    }
  };

  const updateSubSteps = (cassette) => {
    const substeps = cassette.subSteps;

    const subNodes = substeps.map((subStep, idx) => ({
      id: `${idx}`,
      position: { x: 250 * (idx % 2), y: idx * 100 },
      data: { label: `${translate(subStep.name)} (${translate(subStep.status)})` },
      style: {
        background: subStep.status === "DONE" ? "#a0f0a0" :
                    subStep.status === "IN_PROGRESS" ? "#f0a000" :
                    "#f0a0a0",
        color: "#000",
        border: "1px solid #555",
        borderRadius: "5px",
        padding: "10px",
      }
    }));

    const subEdges = substeps.slice(0, -1).map((_, idx) => ({
      id: `e${idx}-${idx + 1}`,
      source: `${idx}`,
      target: `${idx + 1}`,
      type: "smoothstep",
      animated: true,
      style: { stroke: "#555" },
    }));

    setSubStepsData({ nodes: subNodes, edges: subEdges });
  };

  const handleCassetteChange = (e) => {
    const cassetteId = e.target.value;
    setSelectedCassette(cassetteId);
    const selectedCassette = usedSample.steps.find(step => step.name === "HISTOLOGY_PROCESS")
      .cassettes.find(cassette => cassette.cassetteId === cassetteId);
    updateSubSteps(selectedCassette);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSubStepsData(null);
    setSelectedCassette(null);
  };

  const openReference = () => {
    setIsModalRefOpen(true);
  };

  const closeReference = () => {
    setIsModalRefOpen(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('es-AR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    const formattedTime = date.toLocaleTimeString('es-AR', {
      hour: '2-digit',
      minute: '2-digit',
    });

    return `${formattedDate} ${formattedTime}`;
  };

          // Verifico si hubo actualizacion

          useEffect(() => {
            if (sampleUpdate) {
                    const histologyProcessStep = sampleUpdate.steps.find(step => step.name === "HISTOLOGY_PROCESS");
                    const histologyProcessStepNow = usedSample.steps.find(step => step.name === "HISTOLOGY_PROCESS");

                    if (histologyProcessStep && histologyProcessStep.cassettes) {
                        const updatedCassettes = histologyProcessStepNow.cassettes.map(cassetteNow => {
                            const updatedCassette = { ...cassetteNow };

                            histologyProcessStep.cassettes.forEach(cassette => {
                                if (cassetteNow.cassetteId === cassette.cassetteId) {
                                  updateSubSteps({ ...cassette });
                                }
                            });

                            // Actualiza los vidrios
                            updatedCassette.glassSlides = updatedCassette.glassSlides.map(glassSlideNow => {
                                const updatedGlassSlide = histologyProcessStep.cassettes
                                    .find(cassette => cassette.cassetteId === updatedCassette.cassetteId)
                                    ?.glassSlides.find(slide => slide.glassSlideId === glassSlideNow.glassSlideId);

                                    if (updatedGlassSlide) {

                                      const updatedGlassSlideInstance = {
                                          ...glassSlideNow,
                                          status: updatedGlassSlide.status ,
                                          startTimestamp: updatedGlassSlide.startTimestamp,
                                          endTimestamp: updatedGlassSlide.endTimestamp,
                                      };

                                      setUsedSample(sampleUpdate);

                                      return updatedGlassSlideInstance;
                                  }

                                return glassSlideNow;
                            });

                            return updatedCassette;
                        });


                    }
            }
        }, [sampleUpdate]);


    const [notification, setNotification] = useState(null);
    const [notification_substep, setNotification_substep] = useState(null);

    useEffect(() => {
      // quiero que los admins o jefes de lab puedan ver esto (o la misma persona que lo hizo)
      if (sampleUpdate && (session.userType == 'ADMIN' || session.userType == 'HEAD_OF_LABORATORY' || session.username == sampleUpdate.assignedTo)){

        if (isModalOpen)
          setNotification_substep(`Nuevo proceso de laboratorio: ${sampleUpdate.protocolNumber} hecho por ${sampleUpdate.assignedTo}`);
        else
          setNotification(`Nuevo proceso de laboratorio: ${sampleUpdate.protocolNumber} hecho por ${sampleUpdate.assignedTo}`);

        setTimeout(() => {
          if (isModalOpen) setNotification_substep(null); else setNotification(null);
          dispatch(setSampleUpdate(undefined));
        }, 5000);

      }

    }, [sampleUpdate]);

    const proOptions = { hideAttribution: true }; // esto es para que no se muestre el banner "React flow"

  return (
    <div key={refreshKey} style={{ height: 830, width: "100%", border: "1px solid #ccc", position: "relative", display: "flex" }}>
             <div style={{ flex: 1, margin: "5px" }}>   
      {notification && (
            <div className="notification_assign">
              {notification}
            </div>
          )}
           <div> <b>Flujo de trabajo</b></div>
           <div><FaLightbulb color="yellow" size="24px" /> <a  style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={openReference}>Referencias</a></div>
      {hoveredNode && (
        <div style={{
          position: 'absolute',
          top: '15px',
          left: '10px',
          background: '#fff',
          padding: '10px',
          border: '1px solid #000',
          zIndex: 10
        }}>
          <strong>Tiempo transcurrido:</strong> {hoveredNode.timeDiff}<br />
          {hoveredNode.doneBy && (
            <>
              <strong>Hecho por:</strong> {hoveredNode.doneBy}<br />
            </>
          )}
           {hoveredNode.assignedBy && (
           <><strong>Asignado por:</strong> {hoveredNode.assignedBy}<br /></>
          )}
           {hoveredNode.assignedTo && (
           <><strong>Asignado a:</strong> {hoveredNode.assignedTo}<br /></>
          )}
           {hoveredNode.start && (
           <><strong>Comienzo:</strong> {formatDate(hoveredNode.start)}<br /></>
          )}
           {hoveredNode.end && (
           <><strong>Fin:</strong> {formatDate(hoveredNode.end)}<br /></>
          )}
        </div>
      )}




      <ReactFlow
        nodes={nodes}
        edges={edges}
        fitView
        onNodeMouseEnter={handleNodeMouseEnter}
        onNodeMouseLeave={handleNodeMouseLeave}
        onNodeClick={handleNodeClick}
        proOptions={proOptions}

      >
        <Background />
      </ReactFlow>

      {isModalRefOpen &&  (
     <ReferencesModalDiagram closeReference={closeReference}/>
      )}


      {isModalOpen && subStepsData && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}>
          <div style={{
            background: '#fff',
            padding: '20px',
            borderRadius: '8px',
            width: '80%',
            height: '80%',
            position: 'relative',
          }}>
               {notification_substep && (
              <div className="notification_assign">
                {notification_substep}
              </div>
            )}
            <h2>Subpasos del proceso histológico</h2>
            <label htmlFor="cassetteSelect">Selecciona un cassette:</label>
            <select id="cassetteSelect" value={selectedCassette} onChange={handleCassetteChange}>
              {usedSample.steps.find(step => step.name === "HISTOLOGY_PROCESS").cassettes.map(cassette => (
                <option key={cassette.cassetteId} value={cassette.cassetteId}>
                  Cassette {cassette.cassetteId}
                </option>
              ))}
            </select>
            <div style={{ display: 'flex', height: '70%', width: '100%', marginTop: '10px' }}>
            <div style={{ flex: 1 }}>
              <ReactFlow
                nodes={subStepsData.nodes}
                edges={subStepsData.edges}
                fitView
              >
                <Background />
              </ReactFlow>
            </div>

            <div style={{ width: '300px', marginLeft: '20px', marginRight: '90px' }}>
              <h3>Vidrios:</h3>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>ID</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Estado</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Técnica de coloración</th>
                  </tr>
                </thead>
                <tbody>
                  {usedSample.steps
                    .find(step => step.name === "HISTOLOGY_PROCESS")
                    ?.cassettes[selectedCassette.split('_')[1]]
                    ?.glassSlides?.map(slide => (
                      <tr key={slide.id}>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{slide.glassSlideId}</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{translate(slide.status)}</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{slide.colorationTechnique}</td>
                      </tr>
                    )) || (
                      <tr>
                        <td colSpan="2" style={{ textAlign: 'center' }}>No hay datos disponibles</td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>



            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '40px',
                paddingLeft: '0px' 
              }}>
                <button className="close-button" onClick={closeModal}>Cerrar</button>
            </div>
          </div>
        </div>
      )}
       </div>
       <div style={{ flex: 1, border: "1px solid #ccc", margin: "5px" }}>
       <SampleDetailExtended selectedSample={data}>
       </SampleDetailExtended>
    </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sampleUpdate: selectors.sampleUpdate(state),
  session: sessionSelector.session(state),
});

export default connect(mapStateToProps)(StateDiagram);

