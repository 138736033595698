import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { selectors as sessionSelector } from "../redux/reducers/sessionReducer";
import { selectors as authSelector } from "../redux/reducers/auth";
import { selectors as metricsSelectors, getStatsRequest } from "../redux/reducers/metrics";
import ContentWrapper from './commons/ContentWrapper';
import StatisticsChart from './dashboards/StatisticsChart';

import './css/dashboard.css';

function Metrics({ loading, error, stats }) {
  const dispatch = useDispatch();

  useEffect(() => {
  dispatch(getStatsRequest());
}, [dispatch]);



  return (
    <ContentWrapper title="Estadísticas" loading={loading} error={error} onBack={undefined}>
       {stats &&
      <StatisticsChart data={stats} />
    }

    </ContentWrapper>)
}

const mapStateToProps = (state) => ({
  session: sessionSelector.session(state),
  auth: authSelector.login(state),
  stats: metricsSelectors.stats(state),
  loading: metricsSelectors.loading(state),
  error: metricsSelectors.error(state),
});

export default connect(mapStateToProps)(Metrics);
