import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import './css/registrarMuestra.css';
import {
  getEntity, entityType, selectors
} from '../../redux/reducers/entity';
import {
  selectors as sampleSelectors
} from '../../redux/reducers/sample';
import DatosPersonales from './DatosPersonales';
import DatosProfesional from './DatosProfesional';
import Hospital from './Hospital';
import MaterialRemitido from './MaterialRemitido';
import DatosClinicos from './DatosClinicos';
import MaterialGinecologico from './MaterialGinecologico';
import TipoMuestra from './TipoMuestra';
import { addSample } from '../../redux/reducers/sample';
import ContentWrapper from '../commons/ContentWrapper';
import Modal from '../commons/Modal';
import ConfirmationModal from '../commons/ConfirmationModal';

const RegistrarMuestra = ({ hospitalesList, diagnosticoClinicoList, loading, error, modalLoading }) => {

  const initForm = {
    sampleSubType: '',
    nombreApellido: '',
    edad: '',
    dni: '',
    domicilio: '',
    nacionalidad: '',
    profesion: '',
    estadoCivil: '',
    email: '',
    hospitalSolicitante: 'Hospital Zonal General de Agudos Dr. Diego Thompson - San Martín',
    sala: '',
    cama: '',
    materialRemitido: '',
    sitioTomaMuestra: '',
    metodoObtencionMuestra: '',
    datosClinicos: '',
    tiempoEvolucion: '',
    tratamientoLocalAnterior: '',
    materialGinecologico: '',
    cicloMenstrual: '',
    ultimaMenstruacion: '',
    fechaUltimoParto: '',
    diagnosticoClinico: '',
    biopsiaAnterior: 'NO',
    resultadoBiopsiaAnterior: '',
    datosMedicoNombreApellido: '',
    especialidad: '',
    nroMatricula: '',
    patientBirthdate: undefined,
		clinicHistoryNumber: ''  }

  const [formData, setFormData] = useState(initForm);

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      hospitalSolicitante: e.target.name,
    }));
  };

  const handleSubmit = (e) => {
		e.preventDefault();
		const form = e.target;
    setShowModalConfirmation(true);
  };

  const confirmSampleAdd = () => {
    setShowModalConfirmation(false);
    setShowModal(true);

    const sampleData = {
      sampleSubtype: formData.sampleSubType,
      type: formData.materialRemitido,
      patientDNI: formData.dni,
      patientEmail: formData.email,
      patientBirthdate: formData.patientBirthdate,
      patientName: formData.nombreApellido,
      patientAddress: formData.domicilio,
			patientMaritalStatus: formData.estadoCivil,
			clinicHistoryNumber: formData.clinicHistoryNumber,

      // hospital fields
      hospitalName: formData.hospitalSolicitante,
      hospitalRoom: formData.sala,
      bed: formData.cama,

      requestingDoctorName: formData.datosMedicoNombreApellido,
      requestingDoctorLastName: '',
      requestingDoctorSpecialty: formData.especialidad,
      requestingDoctorRegistrationNumber: formData.nroMatricula,

      samplingDescription: formData.materialRemitido,
      samplingSite: formData.sitioTomaMuestra,
      samplingMethod: formData.metodoObtencionMuestra,

      evolutionTime: formData.tiempoEvolucion,
      previousTreatment: formData.tratamientoLocalAnterior,
      clinicalDiagnosis: formData.diagnosticoClinico,
      previousBiopsy: formData.biopsiaAnterior,
      previousBiopsyResult: formData.resultadoBiopsiaAnterior,
    };

    dispatch(addSample(sampleData));
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const confirmOperation = () => {
    setFormData(initForm);
    setShowModal(false);
  };

  const [hospitales, setHospitales] = useState([]);
  const [diagnosticoClinico, setDiagnosticoClinico] = useState([]);

  useEffect(() => {
    if (hospitalesList)
      setHospitales(hospitalesList);
    if (diagnosticoClinicoList)
      setDiagnosticoClinico(diagnosticoClinicoList);
  }, []);

  useEffect(() => {
    // Cargo las entidades, pueden haber mas
    dispatch(getEntity( [entityType.DIAGNOSTICO_CLINICO, entityType.HOSPITAL]));
}, [dispatch]);

  return (
    <ContentWrapper title="Registrar muestra" loading={loading} error={error}>

        <form onSubmit={handleSubmit} className="form">

        <TipoMuestra formData={formData} handleChange={handleChange} />
        <DatosPersonales formData={formData} handleChange={handleChange} />
        <Hospital formData={formData}  handleChange={handleChange} handleSelectChange={handleSelectChange} hospitales={hospitales}/>
        <DatosProfesional formData={formData} handleChange={handleChange} />
        <MaterialRemitido formData={formData} handleChange={handleChange} />
        <DatosClinicos formData={formData} handleChange={handleChange} diagnosticoClinico={diagnosticoClinico}/>
        <MaterialGinecologico formData={formData} handleChange={handleChange} />

        <button type="submit" className="submit-button">Enviar</button>
      </form>

        {showModal && (
          <Modal
            modalLoading={modalLoading}
            error={error}
            text={"Se descargaron las etiquetas."}
            onClose={() => confirmOperation()}
          />
        )}
        {showModalConfirmation && (
          <ConfirmationModal
            message={`Confirma registro de muestra?`}
            onConfirm={() => confirmSampleAdd()}
            onCancel={() => setShowModalConfirmation(false)}
          />
        )}

  </ContentWrapper>
  );
};

const mapStateToProps = (state) => ({
  hospitalesList:  selectors.entities(state, entityType.HOSPITAL),
  diagnosticoClinicoList:selectors.entities(state, entityType.DIAGNOSTICO_CLINICO),
  loading: selectors.loading(state),
  modalLoading: sampleSelectors.modalLoading(state),
  error: sampleSelectors.error(state),
});

export default connect(mapStateToProps)(RegistrarMuestra);
