import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { translate } from '../../utils/translate';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SamplesBySubtypeAndDayChart = ({ data }) => {
  const subtypes = [...new Set(data?.totalSamplesTypePerDay?.map(sample => sample.subtype))];
  const dates = [...new Set(data?.totalSamplesTypePerDay?.map(sample => sample.date))];

  const colors = [
    'rgba(255, 19, 12, 0.6)', // Color 1
    'rgba(54, 162, 235, 0.6)', // Color 2
    'rgba(255, 206, 86, 0.6)', // Color 3
    'rgba(75, 192, 192, 0.6)', // Color 4
    'rgba(153, 102, 255, 0.6)', // Color 5
    'rgba(255, 159, 64, 0.6)'  // Color 6
  ];

  const datasetsBySubtype = subtypes.map((subtype, index)  => {
    return {
      label: `Muestras de tipo ${translate(subtype)}`,
      data: dates.map(date => {
        const sampleForDayAndSubtype = data.totalSamplesTypePerDay.find(sample => sample.date === date && sample.subtype === subtype);
        return sampleForDayAndSubtype ? sampleForDayAndSubtype.count : 0;
      }),
      backgroundColor: colors[index % colors.length],
    };
  });

  const chartDataBySubtypeAndDay = {
    labels: dates,
    datasets: datasetsBySubtype
  };

  const options = {
    responsive: true,
    indexAxis: 'y',
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        }
      },
      y: {
        ticks: {
          max: 10,
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Muestras por Subtipo y Día',
      }
    },
    elements: {
      bar: {
        barThickness: 6, // Ajusta el grosor de las barras
        categoryPercentage: 0.4, // Ajusta el espacio entre barras
        barPercentage: 0.4, // Ajusta el espacio entre barras
      }
    },
    aspectRatio: 2, // Cambia la relación de aspecto para hacerlo más grande
  };

  return (
    <div style={{ width: '800px', height: '600px'}}> {/* Ajusta el tamaño del contenedor */}
      <Bar data={chartDataBySubtypeAndDay} options={options} />
    </div>
  );
};

export default SamplesBySubtypeAndDayChart;
