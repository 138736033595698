import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import './css/parametrizacion.css';
import { selectors } from '../redux/reducers/sample';
import ContentWrapper from './commons/ContentWrapper';
import Modal from './commons/Modal';
import ConfirmationModal from './commons/ConfirmationModal';
import { getArchiveSampleRequest, getSampleLocationsRequest } from '../redux/reducers/sample';
import SampleLocationBoxes from './SampleLocationBoxes';

const ConsultarUbicacionMuestra = ({ loading, error, modalLoading, sampleLocation, sampleLocations }) => {
  const dispatch = useDispatch();

  const initForm = {
    protocolNumber: '',
  };

  const [showModal, setShowModal] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [formData, setFormData] = useState(initForm);

  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const query = {
      protocolNumber: formData.protocolNumber,
    };
    
    dispatch(getSampleLocationsRequest(query));
  };

  useEffect(() => {

    const query = {
      protocolNumber: formData.protocolNumber,
    };
    
    dispatch(getSampleLocationsRequest(query));
  }, [dispatch]);

  const confirmLocation = () => {
    setShowModalConfirmation(false);
   // const getLocationRequest = { protocolNumber: formData.protocolNumber };

    const query = {
      protocolNumber: formData.protocolNumber,
    };
    
    dispatch(getSampleLocationsRequest(query));


   // dispatch(getArchiveSampleRequest(getLocationRequest));

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const confirmOperation = () => {
    setFormData(initForm);
    setShowModal(false);
  };

  return (
    <ContentWrapper title="Consultar ubicación de muestra" loading={loading} error={error}>
      <form onSubmit={handleSubmit} className="form">
        <div className="form-group">
          <label>Nro de protocolo:</label>
          <input
            type="text"
            name="protocolNumber"
            value={formData.protocolNumber}
            onChange={handleChange}
          />
        </div>
        {sampleLocations && sampleLocations.length == 0 && (<>
       No hay ubicaciones para mostrar con el filtro ingresado <br /><br />
      </>)}
      

        {sampleLocations && sampleLocations.length > 0 && (<>
        <br /><br />
        <SampleLocationBoxes sampleLocations={sampleLocations} setSelectedLocation={setSelectedLocation} selectedLocation={selectedLocation} />
        <br /><br />
      </>)}
      

        <button type="submit" className="submit-button-param">Enviar</button>
      </form>

      {showModal && (
        <Modal
          modalLoading={modalLoading}
          error={error}
          text={sampleLocation ? sampleLocation : ''}
          onClose={() => confirmOperation()}
        >
         
        </Modal>
      )}
      
      {showModalConfirmation && (
        <ConfirmationModal
          message={`Confirma búsqueda?`}
          onConfirm={() => confirmLocation()}
          onCancel={() => setShowModalConfirmation(false)}
        />
      )}
    </ContentWrapper>
  );
};

const mapStateToProps = (state) => ({
  modalLoading: selectors.modalLoading(state),
  loading: selectors.loading(state),
  error: selectors.error(state),
  sampleLocation: selectors.sampleLocation(state),
  sampleLocations: selectors.sampleLocations(state),
});

export default connect(mapStateToProps)(ConsultarUbicacionMuestra);
