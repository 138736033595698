import parse from 'html-react-parser';
import { translate } from '../utils/translate';

export const findReportDescriptionForSample = (sample) => {
    const generateReportStep = sample.steps.find(step => step.name === 'GENERATE_REPORT');
    return generateReportStep.reportDescription;
  }

  export const findDiagnosisDescriptionForSample = (sample) => {
    const generateReportStep = sample.steps.find(step => step.name === 'DIAGNOSTIC');
    return <div>
      <p>{generateReportStep ? parse(generateReportStep.diagnosisDescription) : null}</p>
    </div>
  }

  // funcion que obtiene las tecnicas usadas en el proceso de laboratorio para histologia
  export const getUsedTechnicsForHistology = (sample) => {
    return Array.from(
        new Set(
            (sample.steps || [])
                .flatMap(step => step.cassettes || [])
                .flatMap(cassette => cassette.subSteps || [])
                .map(subStep => translate(subStep.name))
        )
    );
  }

export const getMacroscopyDescription = (sample) =>{
	const cassettes = sample.steps.find(step => step.name === 'HISTOLOGY_PROCESS').cassettes;

	return (
		<div>
			<p>Cantidad de cassettes: {cassettes.length}</p>
			<p>Descripción:</p>
			<ul>
				{cassettes.map((cassette, cassetteIndex) => (
					<li key={cassette.cassetteId}>
						<p>Cassette {cassetteIndex} con {cassette.glassSlides.length} vidrios:</p>
						<ul>
							{cassette.glassSlides.map((slide, slideIndex) => (
								<li key={slide.glassSlideId}>
									Vidrio {slideIndex}: técnica de coloración: {slide.colorationTechnique}
								</li>
							))}
						</ul>
					</li>
				))}
			</ul>
		</div>
	);
}

  // funcion que obtiene las tecnicas usadas en el proceso de laboratorio para citologia
export const getUsedTechnicsForCitology = (sample) => {
  const steps = sample.steps || [];
  const startIdx = steps.findIndex(step => step.name === "ASSIGN_TECHNICIAN");
  const endIdx = steps.findIndex(step => step.name === "ASSIGN_DOCTOR");

  if (startIdx !== -1 && endIdx !== -1 && endIdx > startIdx) {
      return steps.slice(startIdx + 1, endIdx).map(step => translate(step.name));
  }

  return [];
}

// Obtiene el autor de la macroscopia
export const  getDoneByForMacroscopyStep = (sample) => {
  const macroscopyStep = sample.steps.find(step => step.name === "MACROSCOPY");
  return macroscopyStep ? macroscopyStep.doneBy : null;
}

// tiempos predefinidos, suman 30 pero ver si ajustar alguno
export const standardTimes = {
  ASSIGN_TECHNICIAN: 3 * 60 * 1000,
  HISTOLOGY_PROCESS: 8 * 60 * 1000,
  FIXATION: 1 * 60 * 1000,
  INCLUSION: 1 * 60 * 60 * 1000,
  CRIOSTATE_CUT: 1 * 60 * 1000,
  COLORATION_PROCESS: 1 * 60 * 60 * 1000,
  ASSIGN_DOCTOR: 5 * 60 * 1000,
  DIAGNOSTIC: 3 * 60 * 60 * 1000,
  ARCHIVE_SAMPLES: 2 * 60 * 1000,
  GENERATE_REPORT: 3 * 60 * 1000,
  SEND_REPORT: 2 * 60 * 1000
};


export function getDelayedSteps(sample, standardTimes) {
  const delayedSteps = [];

  sample.steps.forEach(step => {
      const standardTime = standardTimes[step.name];

      if (standardTime && step.status === "DONE" && step.startTimestamp && step.endTimestamp) {
          const startTime = new Date(step.startTimestamp).getTime();
          const endTime = new Date(step.endTimestamp).getTime();
          const duration = endTime - startTime;

          if (duration > standardTime) {
              delayedSteps.push({
                  step: step.name,
                  actualTime: duration,
                  standardTime: standardTime
              });
          }
      }

      if (step.cassettes) {
          step.cassettes.forEach(cassette => {
              cassette.subSteps.forEach(subStep => {
                  const subStandardTime = standardTimes[subStep.name];

                  if (subStandardTime && subStep.status === "DONE" && subStep.startTimestamp && subStep.endTimestamp) {
                      const subStartTime = new Date(subStep.startTimestamp).getTime();
                      const subEndTime = new Date(subStep.endTimestamp).getTime();
                      const subDuration = subEndTime - subStartTime;

                      if (subDuration > subStandardTime) {
                          delayedSteps.push({
                              step: `${subStep.name}`,
                              actualTime: subDuration,
                              standardTime: subStandardTime
                          });
                      }
                  }
              });
          });
      }
  });

  return delayedSteps;
}


export function analyzeStepsAgainstAverage(samples) {
  const stepTimes = {};
  const stepCounts = {};

  samples.forEach(sample => {
    sample.steps.forEach(step => {
      if (step.status === "DONE" && step.startTimestamp && step.endTimestamp) {
        const startTime = new Date(step.startTimestamp).getTime();
        const endTime = new Date(step.endTimestamp).getTime();
        const duration = endTime - startTime;

        if (!stepTimes[step.name]) {
          stepTimes[step.name] = 0;
          stepCounts[step.name] = 0;
        }
        stepTimes[step.name] += duration;
        stepCounts[step.name] += 1;
      }

      if (step.cassettes) {
        step.cassettes.forEach(cassette => {
          cassette.subSteps.forEach(subStep => {
            if (subStep.status === "DONE" && subStep.startTimestamp && subStep.endTimestamp) {
              const subStartTime = new Date(subStep.startTimestamp).getTime();
              const subEndTime = new Date(subStep.endTimestamp).getTime();
              const subDuration = subEndTime - subStartTime;

              if (!stepTimes[subStep.name]) {
                stepTimes[subStep.name] = 0;
                stepCounts[subStep.name] = 0;
              }
              stepTimes[subStep.name] += subDuration;
              stepCounts[subStep.name] += 1;
            }
          });
        });
      }
    });
  });

  const averageTimes = {};
  for (const [stepName, totalDuration] of Object.entries(stepTimes)) {
    averageTimes[stepName] = totalDuration / stepCounts[stepName];
  }

  return averageTimes;


}

export function getSamplesWithReportCompleted(samples) {
  return samples.filter(sample => 
      sample.steps.some(step => 
          step.name === "GENERATE_REPORT" && step.status === "DONE"
      )
  );
}
