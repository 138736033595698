export const types = {
  ADD_SAMPLE_REQUEST: 'ADD_SAMPLE_REQUEST',
  ADD_SAMPLE_SUCCESS: 'ADD_SAMPLE_SUCCESS',
  ADD_SAMPLE_FAILURE: 'ADD_SAMPLE_FAILURE',

  LIST_SAMPLE_REQUEST: 'LIST_SAMPLE_REQUEST',
  LIST_SAMPLE_SUCCESS: 'LIST_SAMPLE_SUCCESS',
  LIST_SAMPLE_FAILURE: 'LIST_SAMPLE_FAILURE',

  SET_SAMPLE_OWNER_REQUEST: 'SET_SAMPLE_OWNER_REQUEST',
  SET_SAMPLE_OWNER_SUCCESS: 'SET_SAMPLE_OWNER_SUCCESS',
  SET_SAMPLE_OWNER_FAILURE: 'SET_SAMPLE_OWNER_FAILURE',

  MACROSCOPY_REQUEST: 'MACROSCOPY_REQUEST',
  MACROSCOPY_SUCCESS: 'MACROSCOPY_SUCCESS',
  MACROSCOPY_FAILURE: 'MACROSCOPY_FAILURE',

  SET_SELECTED_SAMPLE: 'SET_SELECTED_SAMPLE',

  SET_MACROSCOPY_DATA: 'SET_MACROSCOPY_DATA',

  GET_DASHBOARD_STATS_REQUEST: 'GET_DASHBOARD_STATS_REQUEST',
  GET_DASHBOARD_STATS_SUCCESS: 'GET_DASHBOARD_STATS_SUCCESS',
  GET_DASHBOARD_STATS_FAILURE: 'GET_DASHBOARD_STATS_FAILURE',

  GENERATE_DIAGNOSIS_REQUEST: 'GENERATE_DIAGNOSIS_REQUEST',
  GENERATE_DIAGNOSIS_SUCCESS: 'GENERATE_DIAGNOSIS_SUCCESS',
  GENERATE_DIAGNOSIS_FAILURE: 'GENERATE_DIAGNOSIS_FAILURE',

  GENERATE_INFORME_REQUEST: 'GENERATE_INFORME_REQUEST',
  GENERATE_INFORME_SUCCESS: 'GENERATE_INFORME_SUCCESS',
  GENERATE_INFORME_FAILURE: 'GENERATE_INFORME_FAILURE',

  UPDATE_ARCHIVE_SAMPLE_REQUEST: 'UPDATE_ARCHIVE_SAMPLE_REQUEST',
  UPDATE_ARCHIVE_SAMPLE_SUCCESS: 'UPDATE_ARCHIVE_SAMPLE_SUCCESS',
  UPDATE_ARCHIVE_SAMPLE_FAILURE: 'UPDATE_ARCHIVE_SAMPLE_FAILURE',

  GET_ARCHIVE_SAMPLE_REQUEST: 'GET_ARCHIVE_SAMPLE_REQUEST',
  GET_ARCHIVE_SAMPLE_SUCCESS: 'GET_ARCHIVE_SAMPLE_SUCCESS',
  GET_ARCHIVE_SAMPLE_FAILURE: 'GET_ARCHIVE_SAMPLE_FAILURE',

  SET_DASHBOARD_DATA: 'SET_DASHBOARD_DATA',

  SET_NOTIFICATION_ASSIGN: 'SET_NOTIFICATION_ASSIGN',

  SET_HIGHLIGHT: 'SET_HIGHLIGHT',

  SET_SAMPLE_UPDATE: 'SET_SAMPLE_UPDATE',

  REFRESH_SAMPLES_REQUEST: 'REFRESH_SAMPLES_REQUEST',

  GET_SAMPLE_LOCATIONS_REQUEST: 'GET_SAMPLE_LOCATIONS_REQUEST',
  GET_SAMPLE_LOCATIONS_SUCCESS: 'GET_SAMPLE_LOCATIONS_SUCCESS',
  GET_SAMPLE_LOCATIONS_FAILURE: 'GET_SAMPLE_LOCATIONS_FAILURE',

  SEND_REPORT_REQUEST: 'SEND_REPORT_REQUEST',
  SEND_REPORT_SUCCESS: 'SEND_REPORT_SUCCESS',
  SEND_REPORT_FAILURE: 'SEND_REPORT_FAILURE',

  GET_SAMPLES_ASSIGNATIONS_REQUEST: 'GET_SAMPLES_ASSIGNATIONS_REQUEST',
  GET_SAMPLES_ASSIGNATIONS_SUCCESS: 'GET_SAMPLES_ASSIGNATIONS_SUCCESS',
  GET_SAMPLES_ASSIGNATIONS_FAILURE: 'GET_SAMPLES_ASSIGNATIONS_FAILURE',

  GET_ALL_SAMPLES_REQUEST: 'GET_ALL_SAMPLES_REQUEST',
  GET_ALL_SAMPLES_SUCCESS: 'GET_ALL_SAMPLES_SUCCESS',
  GET_ALL_SAMPLES_FAILURE: 'GET_ALL_SAMPLES_FAILURE',
}

const initialState = {
    data: null,
    loading: false,
    error: null,
    modalLoading: false,
    selectedSample: undefined,
    macroscopyData: {
      protocolNumber: '',
      tissue: '',
      cassettes: [],
    },
    dashdata: undefined,
    dashresult: undefined,
    sampleLocation: undefined,
    notification_assign: undefined,
    highlight: false,
    sampleUpdate: undefined,
    sampleLocations: undefined,
    sampleAssignations: undefined,
    samplesAll: undefined,
};

const sample = (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_SAMPLE_REQUEST:
            return {...state, modalLoading: true, error: null};
        case types.ADD_SAMPLE_SUCCESS:
            return {
                ...state, modalLoading: false, entities: action.entities
            };
        case types.ADD_SAMPLE_FAILURE:
            return {...state, modalLoading: false, error: action.error};
        case types.LIST_SAMPLE_REQUEST:
            return {...state, loading: true, error: null, session: action.session};
        case types.LIST_SAMPLE_SUCCESS:
            return {...state, loading: false, data: action.samples, error: null,
            };
        case types.LIST_SAMPLE_FAILURE:
            return {...state, loading: false, error: action.error, data: null };
        case types.SET_SAMPLE_OWNER_REQUEST:
              return {...state, modalLoading: true, error: null};
        case types.SET_SAMPLE_OWNER_SUCCESS:
            return {...state, modalLoading: false, selectedSample: action.sample,
          };
        case types.SET_SAMPLE_OWNER_FAILURE:
            return {...state, modalLoading: false, error: action.error};
        case types.SET_SELECTED_SAMPLE:
          return {...state, selectedSample: action.sample};
        case types.SET_MACROSCOPY_DATA:
          return {...state, macroscopyData: action.macroscopyData};
        case types.MACROSCOPY_REQUEST:
          return {...state, modalLoading: true, error: null};
        case types.MACROSCOPY_SUCCESS:
            return {
                ...state, modalLoading: false,
            };
        case types.MACROSCOPY_FAILURE:
            return {...state, modalLoading: false, error: action.error};
        case types.GET_DASHBOARD_STATS_REQUEST:
            return {...state, loading: true, error: null};
        case types.GET_DASHBOARD_STATS_SUCCESS:
            return {...state, loading: false, dashresult: action.dashresult, error: null,
            };
        case types.GET_DASHBOARD_STATS_FAILURE:
            return {...state, loading: false, error: action.error, data: null };
        case types.GENERATE_DIAGNOSIS_REQUEST:
          return {...state, modalLoading: true, error: null};
        case types.GENERATE_DIAGNOSIS_SUCCESS:
            return {
                ...state, modalLoading: false, entities: action.entities
            };
        case types.GENERATE_DIAGNOSIS_FAILURE:
            return {...state, modalLoading: false, error: action.error};
        case types.GENERATE_INFORME_REQUEST:
          return {...state, modalLoading: true, error: null};
        case types.GENERATE_INFORME_SUCCESS:
            return {
                ...state, modalLoading: false
            };
        case types.GENERATE_INFORME_FAILURE:
            return {...state, modalLoading: false, error: action.error};
        case types.UPDATE_ARCHIVE_SAMPLE_REQUEST:
          return {...state, modalLoading: true, error: null};
        case types.UPDATE_ARCHIVE_SAMPLE_SUCCESS:
            return {
                ...state, modalLoading: false
            };
        case types.UPDATE_ARCHIVE_SAMPLE_FAILURE:
            return {...state, modalLoading: false, error: action.error};
        case types.GET_ARCHIVE_SAMPLE_REQUEST:
          return {...state, modalLoading: true, error: null};
        case types.GET_ARCHIVE_SAMPLE_SUCCESS:
            return {
                ...state, modalLoading: false, sampleLocation: action.sampleLocation,
            };
        case types.GET_ARCHIVE_SAMPLE_FAILURE:
            return {...state, modalLoading: false, error: action.error};
        case types.SET_DASHBOARD_DATA:
          return {...state, dashdata: action.dashdata};
        case types.SET_NOTIFICATION_ASSIGN:
          return {...state, notification_assign: action.notification_assign};
        case types.SET_HIGHLIGHT:
          return {...state, highlight: action.highlight};
        case types.SET_SAMPLE_UPDATE:
          return {...state, sampleUpdate: action.sampleUpdate};
        case types.REFRESH_SAMPLES_REQUEST:
          return {...state, data: action.samples};
        case types.GET_SAMPLE_LOCATIONS_REQUEST:
          return {...state, loading: true, error: null};
        case types.GET_SAMPLE_LOCATIONS_SUCCESS:
            return {
                ...state, loading: false, sampleLocations: action.sampleLocations,
            };
        case types.GET_SAMPLE_LOCATIONS_FAILURE:
            return {...state, loading: false, error: action.error};
        case types.SEND_REPORT_REQUEST:
            return {...state, modalLoading: true, error: null};
        case types.SEND_REPORT_SUCCESS:
            return {
                ...state, modalLoading: false,
            };
        case types.SEND_REPORT_FAILURE:
            return {...state, modalLoading: false, error: action.error};
        case types.GET_SAMPLES_ASSIGNATIONS_REQUEST:
            return {...state, loading: true, error: null};
        case types.GET_SAMPLES_ASSIGNATIONS_SUCCESS:
            return {
                ...state, loading: false, sampleAssignations: action.sampleAssignations,
            };
        case types.GET_SAMPLES_ASSIGNATIONS_FAILURE:
            return {...state, loading: false, error: action.error};
        case types.GET_ALL_SAMPLES_REQUEST:
          return {...state, loading: true, error: null};
        case types.GET_ALL_SAMPLES_SUCCESS:
            return {
                ...state, loading: false, samplesAll: action.samplesAll,
            };
        case types.GET_ALL_SAMPLES_FAILURE:
            return {...state, loading: false, error: action.error};                            
        default:
            return state;
    }
};

export const addSample = ( sampleData ) => ({
  type: types.ADD_SAMPLE_REQUEST,
  sampleData,
  modalLoading: true,
});

export const addSampleSuccess = (entities) => ({
  type: types.ADD_SAMPLE_SUCCESS,
  entities,
  modalLoading: false,
});

export const addSampleFailure = (code, msg) => ({
  type: types.ADD_SAMPLE_FAILURE,
  error: { code, msg},
  modalLoading: false,
});

export const listSamplesRequest = (query, session) => ({
    type: types.LIST_SAMPLE_REQUEST,
    query: query,
    loading: true,
    session: session,
});

export const listSampleSuccess = (samples) => ({
  type: types.LIST_SAMPLE_SUCCESS,
  samples,
  loading: false,
});

export const listSampleFailure = (code, msg) => ({
  type: types.LIST_SAMPLE_FAILURE,
  error: { code, msg, show: true  },
  loading: false,
});

export const setSampleOwnerRequest = (sample) => ({
  type: types.SET_SAMPLE_OWNER_REQUEST,
  modalLoading: true,
  sample,
});

export const setSampleOwnerSuccess = (sample) => ({
  type: types.SET_SAMPLE_OWNER_SUCCESS,
  modalLoading: false,
  sample,
});

export const setSampleOwnerFailure = (code, msg) => ({
  type: types.SET_SAMPLE_OWNER_FAILURE,
  error: { code, msg, show: true  },
  modalLoading: false,
});

export const setSelectedSample = (sample) => ({
  type: types.SET_SELECTED_SAMPLE,
  sample
});

export const setMacroscopyData = (macroscopyData) => ({
  type: types.SET_MACROSCOPY_DATA,
  macroscopyData,
});

export const macroscopyRequest = ( macroscopyData ) => ({
  type: types.MACROSCOPY_REQUEST,
  macroscopyData,
  modalLoading: true,
});

export const macroscopySuccess = () => ({
  type: types.MACROSCOPY_SUCCESS,
  modalLoading: false,
});

export const macroscopyFailure = (code, msg) => ({
  type: types.MACROSCOPY_FAILURE,
  error: { code, msg},
  modalLoading: false,
});

export const listDashRequest = (session) => ({
  type: types.GET_DASHBOARD_STATS_REQUEST,
  loading: true,
  session,
});

export const listDashSuccess = (dashresult) => ({
  type: types.GET_DASHBOARD_STATS_SUCCESS,
  dashresult,
  loading: false,
});

export const listDashFailure = (code, msg) => ({
  type: types.GET_DASHBOARD_STATS_FAILURE,
  error: { code, msg, show: true  },
  loading: false,
});

export const generateDiagnosisRequest = ( sampleData ) => ({
  type: types.GENERATE_DIAGNOSIS_REQUEST,
  sampleData,
  modalLoading: true,
});

export const generateDiagnosisSuccess = (entities) => ({
  type: types.GENERATE_DIAGNOSIS_SUCCESS,
  entities,
  modalLoading: false,
});

export const generateDiagnosisFailure = (code, msg) => ({
  type: types.GENERATE_DIAGNOSIS_FAILURE,
  error: { code, msg},
  modalLoading: false,
});

export const generateInformeRequest = ( sampleData ) => ({
  type: types.GENERATE_INFORME_REQUEST,
  sampleData,
  modalLoading: true,
});

export const generateInformeSuccess = (entities) => ({
  type: types.GENERATE_INFORME_SUCCESS,
  entities,
  modalLoading: false,
});

export const generateInformeFailure = (code, msg) => ({
  type: types.GENERATE_INFORME_FAILURE,
  error: { code, msg},
  modalLoading: false,
});

export const updateArchiveSampleRequest = ( sampleData ) => ({
  type: types.UPDATE_ARCHIVE_SAMPLE_REQUEST,
  sampleData,
  modalLoading: true,
});

export const updateArchiveSampleSuccess = () => ({
  type: types.UPDATE_ARCHIVE_SAMPLE_SUCCESS,
  modalLoading: false,
});

export const updateArchiveSampleFailure = (code, msg) => ({
  type: types.UPDATE_ARCHIVE_SAMPLE_FAILURE,
  error: { code, msg},
  modalLoading: false,
});

export const getArchiveSampleRequest = ( sampleData ) => ({
  type: types.GET_ARCHIVE_SAMPLE_REQUEST,
  sampleData,
  modalLoading: true,
});

export const getArchiveSampleSuccess = (sampleLocation) => ({
  type: types.GET_ARCHIVE_SAMPLE_SUCCESS,
  modalLoading: false,
  sampleLocation,
});

export const getArchiveSampleFailure = (code, msg) => ({
  type: types.GET_ARCHIVE_SAMPLE_FAILURE,
  error: { code, msg},
  modalLoading: false,
});

export const setDashBoardData = (dashdata) => ({
  type: types.SET_DASHBOARD_DATA,
  dashdata,
});

export const setNotificationAssign = (notification_assign) => ({
  type: types.SET_NOTIFICATION_ASSIGN,
  notification_assign,
});

export const setHighlight = (highlight) => ({
  type: types.SET_HIGHLIGHT,
  highlight,
});

export const setSampleUpdate = (sampleUpdate) => ({
  type: types.SET_SAMPLE_UPDATE,
  sampleUpdate,
});

export const refreshSample = (samples) => ({
  type: types.REFRESH_SAMPLES_REQUEST,
  samples,
});

export const getSampleLocationsRequest = (query) => ({
  type: types.GET_SAMPLE_LOCATIONS_REQUEST,
  loading: true,
  query,
});

export const getSampleLocationsSuccess = (sampleLocations) => ({
  type: types.GET_SAMPLE_LOCATIONS_SUCCESS,
  loading: false,
  sampleLocations,
});

export const getSampleLocationsFailure = (code, msg) => ({
  type: types.GET_ARCHIVE_SAMPLE_FAILURE,
  error: { code, msg},
  loading: false,
});

export const sendReportRequest = (sampleData) => ({
  type: types.SEND_REPORT_REQUEST,
  modalLoading: true,
  sampleData,
});

export const sendReportSuccess = () => ({
  type: types.SEND_REPORT_SUCCESS,
  modalLoading: false,
});

export const sendReportFailure = (code, msg) => ({
  type: types.SEND_REPORT_FAILURE,
  error: { code, msg},
  modalLoading: false,
});

export const getSamplesAssignationsRequest = () => ({
  type: types.GET_SAMPLES_ASSIGNATIONS_REQUEST,
  loading: true,
});

export const getSamplesAssignationsSuccess = (sampleAssignations) => ({
  type: types.GET_SAMPLES_ASSIGNATIONS_SUCCESS,
  loading: false,
  sampleAssignations,
});

export const getSamplesAssignationsFailure = (code, msg) => ({
  type: types.GET_SAMPLES_ASSIGNATIONS_FAILURE,
  error: { code, msg},
  loading: false,
});

export const getAllSamplesRequest = () => ({
  type: types.GET_ALL_SAMPLES_REQUEST,
  loading: true,
});

export const getAllSamplesSuccess = (samplesAll) => ({
  type: types.GET_ALL_SAMPLES_SUCCESS,
  loading: false,
  samplesAll,
});

export const getAllSamplesFailure = (code, msg) => ({
  type: types.GET_ALL_SAMPLES_FAILURE,
  error: { code, msg},
  loading: false,
});

export const selectors = {
    loading: state => state.sample.loading,
    modalLoading: state => state.sample.modalLoading,
    error: state => state.sample.error,
    samples: state => state.sample.data,
    selectedSample: state => state.sample.selectedSample,
    session: state => state.session.data,
    macroscopyData: state => state.sample.macroscopyData,
    dashdata: state => state.sample.dashdata,
    dashresult: state => state.sample.dashresult,
    sampleLocation: state => state.sample.sampleLocation,
    notification_assign: state => state.sample.notification_assign,
    highlight: state => state.sample.highlight,
    sampleUpdate: state => state.sample.sampleUpdate,
    sampleLocations: state => state.sample.sampleLocations,
    sampleAssignations: state => state.sample.sampleAssignations,
    samplesAll: state => state.sample.samplesAll,
};

export default sample;


