import React from 'react';
import { connect } from 'react-redux';
import './css/parametrizacion.css';
import './commons/css/lines.css';
import { translate } from '../utils/translate';
import {formatDate} from "../utils/dates";

const SampleDetailExtended = ({ selectedSample }) => {
  return (
    <div className="sample-details">
      <h2>Detalle de la muestra</h2>
			<div className="sample-info">
				<strong>Número de protocolo:</strong> {selectedSample.protocolNumber}<br/>
				<strong>Estado:</strong> {translate(selectedSample.status)}<br/>
				<strong>Tipo:</strong> {translate(selectedSample.type)}<br/>
				<strong>Subtipo:</strong> {translate(selectedSample.subtype)}<br/>
				<strong>Fecha de ingreso:</strong> {formatDate(selectedSample.creationDate)}
			</div>
			<div className="patient-info">
				<h3>Información del paciente</h3>
				<strong>Nombre y apellido:</strong> {selectedSample.patientName}<br />
        <strong>Número de documento:</strong> {selectedSample.patientDNI}<br />
        <strong>Fecha de nacimiento:</strong> {selectedSample.patientBirthdate}<br />
        <strong>Dirección:</strong> {selectedSample.patientAddress}<br />
        <strong>Correo electrónico:</strong> {selectedSample.patientEmail}<br />
        <strong>Estado civil:</strong> {selectedSample.patientMaritalStatus}<br />
        <strong>Número de historia clínica:</strong> {selectedSample.clinicHistoryNumber}
      </div>
      <div className="hospital-info">
        <h3>Hospital</h3>
        <strong>Nombre:</strong> {selectedSample.hospitalData.hospitalName}<br />
				{selectedSample.hospitalData.hospitalRoom &&  <p><strong>Habitación:</strong> {selectedSample.hospitalData.hospitalRoom}</p>}
				{selectedSample.hospitalData.bed && <p><strong>Cama:</strong> {selectedSample.hospitalData.bed}</p>}
      </div>
      <div className="doctor-info">
        <h3>Médico solicitante</h3>
        <strong>Nombre:</strong> {selectedSample.requestingDoctorData.name}<br />
        <strong>Especialidad:</strong> {selectedSample.requestingDoctorData.specialty}<br />
        <strong>Número de Matrícula:</strong> {selectedSample.requestingDoctorData.registrationNumber}
      </div>
      <div className="material-info">
        <h3>Material remitido</h3>
        <strong>Material remitido:</strong> {selectedSample.submittedMaterialData.description}<br />
        <strong>Sitio de toma de muestra:</strong> {selectedSample.submittedMaterialData.samplingSite}<br />
        <strong>Método de toma de muestra:</strong> {selectedSample.submittedMaterialData.samplingMethod}
      </div>
      <div className="clinical-data">
        <h3>Datos clínicos</h3>
        <strong>Diagnóstico clínico:</strong> {selectedSample.clinicalData.clinicalDiagnosis}<br />
				{selectedSample.clinicalData.previousTreatment && <div><strong>Tratamiento local anterior:</strong> {selectedSample.clinicalData.previousTreatment}<br /></div>}
				{selectedSample.clinicalData.evolutionTime && <div><strong>Tiempo de evolución:</strong> {selectedSample.clinicalData.evolutionTime}<br /></div>}
				{selectedSample.clinicalData.previousBiopsyResult && <div><strong>Resultado de biopsia anterior:</strong> {selectedSample.clinicalData.previousBiopsyResult}<br /></div>}
      </div>
    </div>
  );
};

export default connect(undefined)(SampleDetailExtended);
