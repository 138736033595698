import React from 'react';
import { Link } from 'react-router-dom';
import { FaUserCog } from 'react-icons/fa';

const CardResult = ({ dashresult, field, label, link, icon }) => {
  return (
    <div
      className={`stat-card transparent'}`} // Usa clases CSS
     
    >
      <div className={`stat-icon ${icon}`}>
        <FaUserCog size={30} />
      </div>
      <div className="stat-info">
        <h3>{label}</h3>
        {dashresult && field !== 0 ? (
          link ? (
            <Link to={`${link}`}>
              {field} tareas
            </Link>
          ) : (
            <span>{field ? field : ''} tareas</span>
          )
        ) : (
          <b>Sin tareas</b>
        )}
      </div>
    </div>
  );
};

export default CardResult;
