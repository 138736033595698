import React, { useState } from 'react';
import { UserType, getDescriptionById } from '../../constants';
import { deleteUserRequest, getUsers, setUser } from '../../redux/reducers/user';
import { useDispatch, connect } from 'react-redux';
import Modal from '../commons/Modal';
import {
  selectors
} from '../../redux/reducers/user';
import ConfirmationModal from '../commons/ConfirmationModal';
import '../commons/css/lines.css';
import { useNavigate } from 'react-router-dom';
import { selectors as sessionSelector } from "../../redux/reducers/sessionReducer";

const UserList = ({ users, error, modalLoading, session}) => {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [showModalEditConfirmation, setShowModalEditConfirmation] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDelete = (user) => {
    setShowModalConfirmation(true);
    setSelectedUserId(user);
  };

  const confirmOperation = () => {
    setShowModal(false);
    dispatch(getUsers());
  };

  const confirmDelete = () => {
    setShowModalConfirmation(false);
    setShowModal(true);
    dispatch(deleteUserRequest(selectedUserId.id));
    setSelectedUserId(null);
  };

  const handleEdit = (user) => {
    dispatch(setUser(user));
    navigate('/modificarUsuario');
  };

  return (
    <div>
        <>
          <div className="header-row">
            <div className="cell">Usuario</div>
            <div className="cell">Email</div>
            <div className="cell">Tipo</div>
            <div className="cell">Acciones</div>
          </div>
          {error && error.show && (
            <p className="error-message">No se pudo cargar la informacion de usuarios</p>
          )}
          {users.map(user => (
            <div key={user.id}>
              <div className="row">
                <div className="cell">{user.username}</div>
                <div className="cell">{user.email}</div>
                <div className="cell">{getDescriptionById(user.type)}</div>
                <div className="cell">
                  <button className="edit-button" onClick={() => handleEdit(user)}>Modificar</button>
                  {session && session.username != user.username && (
                  <button className="delete-button" onClick={() => handleDelete(user)}>Eliminar</button>
                  )}
                </div>
              </div>
            </div>
          ))}
          {showModal && (
            <Modal
              modalLoading={modalLoading}
              error={error}
              onClose={() => confirmOperation()}
            />
          )}
          {showModalConfirmation && (
            <ConfirmationModal
              message={`Confirma eliminación del usuario ${selectedUserId.username}?`}
              onConfirm={() => confirmDelete()}
              onCancel={() => setShowModalConfirmation(false)}
            />
          )}
        </>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: selectors.loading(state),
  modalLoading: selectors.modalLoading(state),
  error: selectors.error(state),
  session: sessionSelector.session(state),
});

export default connect(mapStateToProps)(UserList);
