import React from 'react';
import './css/ConfirmationModal.css';

const ConfirmationModal = ({ message, onConfirm, onCancel }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                    <>
                        <h2>Confirmación</h2>
                        <p>{message}</p>
                        <div className="button-group">
                            <button className="confirm-button" onClick={onConfirm}>
                                Sí
                            </button>
                            <button className="cancel-button" onClick={onCancel}>
                                No
                            </button>
                        </div>
                    </>
            </div>
        </div>
    );
};

export default ConfirmationModal;
