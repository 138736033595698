import React from 'react';
import './css/SuccessModal.css';

const Modal = ({ error, onClose, modalLoading, text }) => {
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          {modalLoading ? (
            <div className="spinner-container">
              <div className="spinner"></div>
              <p>Cargando...</p>
            </div>
          ) : (
            <>
             {error == null && (<>
              <h2>Operación Exitosa</h2>
              {text}
              </>
            )}
              {error != null && (
              <h2>Se produjo un error</h2>
            )}
              <p>{error && error.msg}</p>
              <button className="close-button" onClick={onClose}>
                Cerrar
              </button>
            </>
          )}
        </div>
      </div>
    );
  };
  
  export default Modal;