import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import '../css/parametrizacion.css';
import ContentWrapper from '../commons/ContentWrapper';
import '../commons/css/lines.css';
import './css/pagination.css';
import ConfirmationModal from '../commons/ConfirmationModal';
import Modal from '../commons/Modal';
import { selectors, setMacroscopyData } from '../../redux/reducers/sample';
import { useNavigate } from 'react-router-dom';
import { selectors as sampleSelectors, macroscopyRequest } from '../../redux/reducers/sample';
import { selectors as sessionSelectors} from "../../redux/reducers/sessionReducer"

const Macroscopy = ({ loading, error, modalLoading, selectedSample, session }) => {
  const dispatch = useDispatch();

  const [protocolNumber, setProtocolNumber] = useState('');
  const [tissue, setTissue] = useState('');
  const [cassettes, setCassettes] = useState([{
		glasses: [{ technique: 'HEMATOXILINA_EOSINA' }]
	}]);
  const [errors, setErrors] = useState({ registers: '' });
  const [showModal, setShowModal] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);

  const navigate = useNavigate();

  const handleCassetteFieldChange = (cassetteIndex, glassIndex, field, value) => {
    const updatedCassettes = [...cassettes];
    updatedCassettes[cassetteIndex].glasses[glassIndex][field] = value;
    setCassettes(updatedCassettes);
  };

  const handleAddGlass = (cassetteIndex) => {
    const updatedCassettes = [...cassettes];
    updatedCassettes[cassetteIndex].glasses.push({ technique: 'HEMATOXILINA_EOSINA' });
    setCassettes(updatedCassettes);
  };

  const handleRemoveGlass = (cassetteIndex, glassIndex) => {
    const updatedCassettes = [...cassettes];
    updatedCassettes[cassetteIndex].glasses = updatedCassettes[cassetteIndex].glasses.filter((_, index) => index !== glassIndex);
    setCassettes(updatedCassettes);
  };

  const handleRemoveCassette = (cassetteIndex) => {
    const updatedCassettes = [...cassettes];
    updatedCassettes.splice(cassetteIndex, 1);
    setCassettes(updatedCassettes);
  };

  // Función para agregar un nuevo casete
  const handleAddCassette = () => {
    const newCassette = {
      glasses: [{ technique: 'HEMATOXILINA_EOSINA' }]
    };
    setCassettes([...cassettes, newCassette]);
  };

  const confirmOperation = () => {
    setShowModalConfirmation(false);
    setErrors(undefined);

    const data = {
      doneBy: session.username,
      cassettes: cassettes.map(cassette => ({
        glasses: cassette.glasses.map(glass => ({
          technique: glass.technique
        })),
      })),
      protocolNumber: selectedSample.protocolNumber,
    };

    dispatch(macroscopyRequest(data));


    setShowModal(true);
  };

  const confirmOperationOk = () => {
    setShowModal(false);
    dispatch(setMacroscopyData(null));
    navigate('/macroscopia');
  };

  const registerOperation = () => {
    const allFieldsFilled = cassettes.every(cassette =>
      cassette.glasses.every(glass => glass.technique)
    );

    if (!allFieldsFilled) {
      setErrors({ registers: 'Por favor, complete todos los campos para cada vidrio/técnica antes de confirmar.' });
      return;
    }

    setShowModalConfirmation(true);
  };

  const buildConfirmText = () => {
    return "Registro de macroscopia realizado. La muestra debe ser asignada por el jefe de laboratorio. Se descargaron las etiquetas.";
  };

  return (
    <ContentWrapper title="Ingreso de cassettes" loading={loading} error={error}>
      <div className="filter-container" style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
        <div>
          <label>Número de protocolo:</label>
          <label>{selectedSample.protocolNumber}</label>
        </div>
        <div>
          <label>Tejido:</label>
          <label>{selectedSample.submittedMaterialData.description}</label>
        </div>
				<div>
					<label>Cantidad de cassettes:</label>
					<label>{cassettes.length}</label>
				</div>
			</div>

			<div>
				<h3>Información de cassettes ingresados:</h3>
        <label>{cassettes.length === 0 ? "No se ingresó ninguno todavía. Seleccione la cantidad desde el campo cantidad de casetes." : ""}</label>
      </div>

      {cassettes.length > 0 && (
        <div className="cassette-info">
          {cassettes.map((cassette, cassetteIndex) => (
            <div key={cassetteIndex} className="cassette-entry" style={{ marginBottom: '20px' }}>
              <p># Cassette {cassetteIndex + 1}</p>

              {cassette.glasses.map((glass, glassIndex) => (
                <div key={glassIndex} className="glass-entry" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <label>Técnica a realizar en el vidrio:</label>
                  <select
                    value={glass.technique}
                    onChange={(e) => handleCassetteFieldChange(cassetteIndex, glassIndex, 'technique', e.target.value)}
                    style={{ marginRight: '10px', width: '300px' }}
                  >
                    <option value="">Seleccione técnica</option>
                    <option value="HEMATOXILINA_EOSINA">HEMATOXILINA EOSINA</option>
                    <option value="GRAM">GRAM</option>
                    <option value="PAS">PAS</option>
                    <option value="TRICROMICA_MASSON">TRICRÓMICA MASSON</option>
                    <option value="GIEMSA">GIEMSA</option>
                    <option value="ZIEHL_NEELSEN">ZIEHL NEELSEN</option>
                    <option value="RETICULINA">RETICULINA</option>
                    <option value="PRUSIA">PRUSIA</option>
                  </select>

                  {cassette.glasses.length > 1 && (
                    <button onClick={() => handleRemoveGlass(cassetteIndex, glassIndex)} className="delete-button" style={{ marginLeft: '10px' }}>
                      Eliminar vidrio
                    </button>
                  )}
                </div>
              ))}

              <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                <button className='edit-button' onClick={() => handleAddGlass(cassetteIndex)} style={{ marginRight: '10px' }}>
                  Agregar vidrio
                </button>
								{cassettes.length > 1 && <button className='delete-button' onClick={() => handleRemoveCassette(cassetteIndex)}>
                  Eliminar cassette
                </button>}
              </div>
            </div>
          ))}

            {/* Botón para agregar casete */}
      <div style={{ marginTop: '20px' }}>
        <button className='edit-button' onClick={handleAddCassette} style={{ marginRight: '10px' }}>
          Agregar cassette
        </button>
      </div>
        </div>
      )}

      {cassettes.length > 0 && (
        <div style={{ marginTop: '20px' }}>
          {errors && errors.registers && <p>{errors.registers}</p>}
          <button className="submit-button-param" onClick={registerOperation}>Confirmar datos</button>
        </div>
      )}



      {showModal && <Modal modalLoading={modalLoading} error={error} onClose={() => setShowModal(false)} />}
      {showModalConfirmation && (
        <ConfirmationModal
          message="Confirma el registro de macroscopía?"
          onConfirm={confirmOperation}
          onCancel={() => setShowModalConfirmation(false)}
        />
      )}
       {showModal && (
        <Modal
          modalLoading={modalLoading}
          error={error}
          text={buildConfirmText()}
          onClose={() => confirmOperationOk()}
        />
      )}
    </ContentWrapper>
  );
};

const mapStateToProps = (state) => ({
  loading: sampleSelectors.loading(state),
  error: sampleSelectors.error(state),
  modalLoading: selectors.modalLoading(state),
  selectedSample: sampleSelectors.selectedSample(state),
  session: sessionSelectors.session(state),
});

export default connect(mapStateToProps)(Macroscopy);
