import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import './css/parametrizacion.css';
import { selectors } from '../redux/reducers/sample';
import ContentWrapper from './commons/ContentWrapper';
import './commons/css/lines.css';
import { useNavigate } from 'react-router-dom';
import Modal from './commons/Modal';
import ConfirmationModal from './commons/ConfirmationModal';
import { updateArchiveSampleRequest, getSampleLocationsRequest } from '../redux/reducers/sample';
import { selectors as sessionSelectors } from "../redux/reducers/sessionReducer";
import SampleLocationBoxes from './SampleLocationBoxes';

const GuardarMuestra = ({ loading, error, modalLoading, session, selectedSample, sampleLocations }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initForm = {
    descripcion: '',
    capacidad: '',
  };

  const [showModal, setShowModal] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [formData, setFormData] = useState(initForm);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isNewLocation, setIsNewLocation] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [errors, setErrors] = useState({ descripcion: '', capacidad: '' });
  const [errors2, setErrors2] = useState({ descripcion: ''});

  const handleSubmit = (e) => {
    e.preventDefault();
    let newErrors = null;
    if (isNewLocation) {
      newErrors = {
        descripcion: formData.descripcion ? '' : 'El nombre de la ubicación es requerido.',
        capacidad: formData.capacidad ? '' : 'La capacidad es requerida.'
      };
      setErrors(newErrors); 
   }
   else
    if (!selectedLocation) {
      newErrors = {
        descripcion: 'Debe seleccionar una ubicación.',
      };
      setErrors2(newErrors);
   }
   
   
     if (newErrors && !newErrors.descripcion && !newErrors.capacidad || selectedLocation) {
      setShowModalConfirmation(true);
    }


  };

  const confirmLocation = () => {
    setShowModalConfirmation(false);
    setShowModal(true);

    const updateRequest = {
      doneBy: session.username,
      protocolNumber: selectedSample.protocolNumber,
      sampleArchiveLocationDescription: selectedLocation ? selectedLocation : formData.descripcion + "|" + formData.capacidad,
    };

    dispatch(updateArchiveSampleRequest(updateRequest));
  };

  useEffect(() => {
    dispatch(getSampleLocationsRequest({}));
  }, [dispatch]);

  const confirmOperation = () => {
    setFormData(initForm);
    setShowModal(false);
    navigate('/ubicacionMuestra');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = () => {
    setIsNewLocation(!isNewLocation);
    setFormData(initForm);
    setSelectedLocation(null);
    setErrors2({ descripcion: ''});
    setErrors({ descripcion: '', capacidad: '' });
  };



  return (
    <ContentWrapper title="Registrar ubicación de muestra" loading={loading} error={error}>
      <form onSubmit={handleSubmit} className="form">
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              checked={isNewLocation}
              onChange={handleCheckboxChange}
            />
            Crear nueva ubicación
          </label>
        </div>

        {isNewLocation ? (
          <>
            <div className="form-group">
              <label>Nombre de la ubicación:</label>
              <input
                type="text"
                name="descripcion"
                value={formData.descripcion}
                onChange={handleChange}
                style={{ width: "450px" }}
              />
              {errors.descripcion && <p className="error-text">{errors.descripcion}</p>}
            </div>

            <div className="form-group">
              <label>Capacidad:</label>
              <input
                type="number"
                name="capacidad"
                value={formData.capacidad}
                onChange={handleChange}
                style={{ width: "450px" }}
              />
              {errors.capacidad && <p className="error-text">{errors.capacidad}</p>}
            </div>
          </>
        ) : (
          <>
            {sampleLocations && sampleLocations.length > 0 && (
              <>
                <p>O selecciona una de las existentes:</p>
                <SampleLocationBoxes
                  sampleLocations={sampleLocations}
                  setSelectedLocation={setSelectedLocation}
                  selectedLocation={selectedLocation}
                  setErrors2={setErrors2}
                />
              </>
            )}
          </>
        )}
        <br />
         {errors2.descripcion && <p className="error-text">{errors2.descripcion}</p>}
        <br />
        <button type="submit" className="submit-button-param" >Registrar</button>
      </form>
      <br />
      {showModal && (
        <Modal
          modalLoading={modalLoading}
          error={error}
          onClose={() => confirmOperation()}
        />
      )}
      {showModalConfirmation && (
        <ConfirmationModal
          message={`¿Confirma la ubicación de la muestra ${selectedSample.protocolNumber} en ${selectedLocation && selectedLocation.split("|")[0] || formData.descripcion}?`}
          onConfirm={() => confirmLocation()}
          onCancel={() => setShowModalConfirmation(false)}
        />
      )}
    </ContentWrapper>
  );
};

const mapStateToProps = (state) => ({
  modalLoading: selectors.modalLoading(state),
  loading: selectors.loading(state),
  error: selectors.error(state),
  session: sessionSelectors.session(state),
  selectedSample: selectors.selectedSample(state),
  sampleLocations: selectors.sampleLocations(state),
});

export default connect(mapStateToProps)(GuardarMuestra);
