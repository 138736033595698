import {call, put, takeLatest } from 'redux-saga/effects';
import {
   getMetricsFailure, getMetricsSuccess, getStatsFailure, getStatsSuccess, types
} from '../reducers/metrics';
import  {mapError} from '../../errorHandler';
import { getRequestWithToken } from '../../security/api';

const baseUrl = process.env.REACT_APP_BACKEND_API_BASE_URL;

function* getHistogram({query}) {
    try {

        const url = `${baseUrl}/api/metrics`;

    //    let response = yield call(getRequestWithToken, url, query);

     const   response = {
            data:  {
                histograms: [
                  {
                    id: 'Total Muestras Ingresadas',
                    timeframe: '7d',
                    from: '2024-10-03T00:00:00Z',
                    to: '2024-10-10T00:00:00Z',
                    values: [
                      { date: '2024-10-03T00:00:00Z', samples: 3 },
                      { date: '2024-10-04T00:00:00Z', samples: 1 },
                      { date: '2024-10-05T00:00:00Z', samples: 2 },
                      { date: '2024-10-06T00:00:00Z', samples: 7 },
                      { date: '2024-10-07T00:00:00Z', samples: 9 },
                      { date: '2024-10-08T00:00:00Z', samples: 0 },
                      { date: '2024-10-09T00:00:00Z', samples: 1 },
                    ],
                  },
                  {
                    id: 'Total Muestras Ingresadas',
                    timeframe: '30d',
                    from: '2024-09-10T00:00:00Z',
                    to: '2024-10-10T00:00:00Z',
                    values: [
                      { date: '2024-09-10T00:00:00Z', samples: 3 },
                      { date: '2024-09-11T00:00:00Z', samples: 1 },
                      { date: '2024-09-12T00:00:00Z', samples: 2 },
                      { date: '2024-09-13T00:00:00Z', samples: 7 },
                    ],
                  },
                ],
              },
        };

         
        yield put(getMetricsSuccess(response.data.histograms));
    } catch (error) {
   
        const msg = mapError(error);
        if (error.code == 'ERR_NETWORK') {
            yield put(getMetricsFailure(error.code, 'Error al realizar la solicitud.'));
            return;
        }
        yield put(getMetricsFailure(error.code, msg));
    }
}

function* getStats() {
  try {

      const url = `${baseUrl}/api/stats`;

      const response = yield call(getRequestWithToken, url);
       
      yield put(getStatsSuccess(response.data));
  } catch (error) {
 
      const msg = mapError(error);
      if (error.code == 'ERR_NETWORK') {
          yield put(getStatsFailure(error.code, 'Error al realizar la solicitud.'));
          return;
      }
      yield put(getStatsFailure(error.code, msg));
  }
}

export function* watchMetricsSaga() {
    yield takeLatest(types.GET_HISTOGRAM_REQUEST, getHistogram);
    yield takeLatest(types.GET_STATS_REQUEST, getStats);
}
