import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { translate } from '../../utils/translate';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const StatisticsChart = ( {data}) => {

  const chartDataByType = {
    labels: data?.totalSamplesByType?.map(type => translate(type.id)) || [],
    datasets: [
      {
        label: 'Muestras por tipo',
        data: data?.totalSamplesByType?.map(type => type.count) || [],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      }
    ]
  };

  const chartDataByTypeInProgress = {
    labels: data?.totalInProgressSamplesByType?.map(type => translate(type.id)) || [],
    datasets: [
      {
        label: 'Muestras en proceso por tipo',
        data: data?.totalInProgressSamplesByType?.map(type => type.count) || [],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      }
    ]
  };

  const chartDataByAssignee = {
    labels: data?.totalSamplesByAssignee?.map(assignee => assignee.id) || [],
    datasets: [
      {
        label: 'Muestras asignadas por persona',
        data: data?.totalSamplesByAssignee?.map(assignee => assignee.count) || [],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      }
    ]
  };

  const chartDataByDay = {
    labels: data?.totalSamplesPerDay?.map(day => day.date) || [],
    datasets: [
      {
        label: 'Muestras por día',
        data: data?.totalSamplesPerDay?.map(day => day.count) || [],
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
      }
    ]
  };

  const colors = [
    'rgba(255, 19, 12, 0.6)', // Color 1
    'rgba(54, 162, 235, 0.6)', // Color 2
    'rgba(255, 206, 86, 0.6)', // Color 3
    'rgba(75, 192, 192, 0.6)', // Color 4
    'rgba(153, 102, 255, 0.6)', // Color 5
    'rgba(255, 159, 64, 0.6)'  // Color 6
  ];

  const subtypes = [...new Set(data?.totalSamplesTypePerDay?.map(sample => sample.subtype))]; // Obtener todos los subtipos únicos
  const dates = [...new Set(data?.totalSamplesTypePerDay?.map(sample => sample.date))]; // Obtener todas las fechas únicas

  const datasetsBySubtype = subtypes.map((subtype, index) => {
    return {
      label: `Muestras de subtipo ${translate(subtype)}`,
      data: dates.map(date => {
        // Filtrar muestras de un día y subtipo específico
        const sampleForDayAndSubtype = data.totalSamplesTypePerDay.find(sample => sample.date === date && sample.subtype === subtype);
        return sampleForDayAndSubtype ? sampleForDayAndSubtype.count : 0;
      }),
      backgroundColor: colors[index % colors.length], // Generar un color aleatorio para cada subtipo
    };
  });

  const chartDataBySubtypeAndDay = {
    labels: dates, // Fechas como etiquetas
    datasets: datasetsBySubtype // Dataset por cada subtipo
  };

  const chartDataDiagnosisTime = {
    labels: data?.diagnosisTimePerSubtype?.map(item => translate(item.subtype)) || [],
    datasets: [
      {
        label: 'Tiempo promedio de diagnóstico (minutos)',
        data: data?.diagnosisTimePerSubtype?.map(item => parseFloat(item.averageDifference)) || [],
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
      }
    ]
  };

  const options = {
    responsive: true,
    indexAxis: 'y', // Hace que las barras sean horizontales
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        }
      },
      y: {
        ticks: {
          max: 10,
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: ''
      }
    },
    elements: {
      bar: {
        barThickness: 4, // Ajusta el grosor de las barras
        categoryPercentage: 0.3, // Ajusta el espacio entre barras
        barPercentage: 0.3, // Ajusta el espacio entre barras
      }
    },
    // Ajusta el ratio de aspecto
    aspectRatio: 1.5, // Cambia este valor para modificar la forma del gráfico
  };


  return (
    <div>
    <h4>Total de muestras: {data.totalSamples}</h4>
    <h4>Total de muestras en proceso: {data.totalSamplesInProgress}</h4>

    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
      <div style={{ flex: '1 1 45%' }}>
        <h3>Muestras por tipo</h3>
        <Bar data={chartDataByType} options={options} />
      </div>
      <div style={{ flex: '1 1 45%' }}>
        <h3>Muestras por tipo en proceso</h3>
        <Bar data={chartDataByTypeInProgress} options={options} />
      </div>

      <div style={{ flex: '1 1 45%' }}>
        <h3>Muestras asignadas por persona</h3>
        <Bar data={chartDataByAssignee} options={options} />
      </div>
      <div style={{ flex: '1 1 45%' }}>
        <h3>Muestras por día</h3>
        <Bar data={chartDataByDay} options={options} />
      </div>

      <div style={{ flex: '1 1 45%' }}>
        <h3>Muestras por subtipo y día</h3>
        <Bar data={chartDataBySubtypeAndDay} options={options} />
      </div>
      <div style={{ flex: '1 1 45%' }}>
        <h3>Tiempo promedio de diagnóstico por subtipo</h3>
        <Bar data={chartDataDiagnosisTime} options={options} />
      </div>
    </div>
  </div>
  );
};

export default StatisticsChart;
