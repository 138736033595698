import React, {useState, useCallback, useEffect} from 'react';
import {useDispatch, connect} from 'react-redux';
import './css/parametrizacion.css';
import {
    selectors
} from '../redux/reducers/sample';
import ContentWrapper from './commons/ContentWrapper';
import './commons/css/lines.css';
import {useNavigate} from 'react-router-dom';
import Modal from './commons/Modal';
import ConfirmationModal from './commons/ConfirmationModal';
import {generateDiagnosisRequest} from '../redux/reducers/sample';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {selectors as sessionSelectors} from "../redux/reducers/sessionReducer"
import SampleDetail from './SampleDetail';
import axios from 'axios';
import debounce from 'lodash/debounce';
import snomedLogo from '../assets/snomed-ct-logo.png';
import icdLogo from '../assets/icd11-logo.png';
import {getRequestWithToken} from '../security/api';
import {runSaga} from 'redux-saga';
import {sagaMiddleware} from "../redux/store";
import * as ECT from '@whoicd/icd11ect';
import '@whoicd/icd11ect/style.css';

const Diagnostico = ({loading, error, modalLoading, session, selectedSample}) => {

    const icd11Container = 'icd11-container';
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initForm = {
        descripcion: '',
        diagnoses: [],
    }
    const [showModal, setShowModal] = useState(false);
    const [showModalConfirmation, setShowModalConfirmation] = useState(false);
    const [formData, setFormData] = useState(initForm);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedDiagnoses, setSelectedDiagnoses] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        const settings = {
            apiServerUrl: 'https://id.who.int',
            apiSecured: true,
            language: 'es',
            linearizationName: 'mms',
            releaseId: '2024-01',
            autoBind: false
        };

        const callbacks = {
            getNewTokenFunction: () => {
                return new Promise((resolve, reject) => {
                    let url = process.env.REACT_APP_BACKEND_API_BASE_URL + '/api/who/token';
                    console.log('calling:', url);

                    function* tokenSaga() {
                        try {
                            const response = yield* getRequestWithToken(url);
                            if (!response.data) {
                                console.log('response status_code:', response.status);
                                resolve(null);
                                return;
                            }
                            console.log('response status code 200:', response);
                            resolve(response.data.access_token);
                        } catch (error) {
                            console.error('Error getting token:', error);
                            reject(error);
                        }
                    }

                    sagaMiddleware.run(tokenSaga);
                });
            },
            selectedEntityFunction: (selectedEntity) => {
                console.log('Selected ICD-11 entity:', selectedEntity);

                const formattedDiagnosis = `
                    <div class="selected-diagnosis">
                        <strong>
                            - ICD-11 Diagnóstico: ${selectedEntity.selectedText} | ${selectedEntity.code}
                        </strong>
                    </div>
                `;

                setFormData(prevData => ({
                    ...prevData,
                    descripcion: prevData.descripcion
                        ? `${prevData.descripcion}${formattedDiagnosis}`
                        : formattedDiagnosis
                }));

                // Reset search input without full reinitialization
                ECT.Handler.clear('icd11-container');
                const input = document.querySelector('input[data-ctw-ino="icd11-container"]');
                if (input) {
                    input.value = '';
                    input.placeholder = 'Buscar código ICD-11...';
                }
            }
        };

        // Initial setup
        ECT.Handler.configure(settings, callbacks);
        ECT.Handler.bind('icd11-container');

        // Cleanup function
        return () => {
            ECT.Handler.clear('icd11-container');
            const input = document.querySelector('input[data-ctw-ino="icd11-container"]');
            if (input) {
                input.placeholder = 'Buscar código ICD-11...';
            }
        };
    }, []);

    useEffect(() => {
        // Any formData.descripcion dependent logic here
    }, [formData.descripcion]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowModalConfirmation(true);
    };

    const confirmDiagnosis = () => {
        setShowModalConfirmation(false);
        setShowModal(true);

        const diagnosisData = {
            doneBy: session.username,
            protocolNumber: selectedSample.protocolNumber,
            description: formData.descripcion,
            diagnoses: formData.diagnoses,
        };

        dispatch(generateDiagnosisRequest(diagnosisData));
    };

    const handleQuillChange = (value) => {
        setFormData((prevData) => ({
            ...prevData,
            descripcion: value,
        }));
    };

    const confirmOperation = () => {
        setFormData(initForm);
        setShowModal(false);
        navigate('/diagnostico');
    };

    const debouncedSearch = useCallback(
        debounce(async (term) => {
            if (term.length < 3) return;

            setIsSearching(true);
            try {
                let url = process.env.REACT_APP_BACKEND_API_BASE_URL + '/api/snomed/search';
                const response = await axios.get(url, {
                    params: {
                        s: term,
                        maxHits: 10
                    }
                });
                setSearchResults(response.data);
                setShowDropdown(true);
            } catch (error) {
                console.error('Error searching diagnoses:', error);
            } finally {
                setIsSearching(false);
            }
        }, 500),
        []
    );

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);

        if (value.length >= 3) {
            debouncedSearch(value);
        } else {
            setSearchResults([]);
            setShowDropdown(false);
        }
    };

    const handleSearch = async () => {
        if (searchTerm.length < 3) {
            alert('Por favor ingrese al menos 3 caracteres para buscar');
            return;
        }

        try {
            const response = await axios.get(`http://3.138.201.77/v1/snomed/search`, {
                params: {
                    s: searchTerm,
                    maxHits: 10
                }
            });
            setSearchResults(response.data);
            setShowDropdown(true);
        } catch (error) {
            console.error('Error searching diagnoses:', error);
        }
    };

    const handleDiagnosisSelect = (diagnosis) => {
        // Format the diagnosis with all information in bold
        const formattedDiagnosis = `
      <div class="selected-diagnosis">
        <strong>
          - SNOMED-CT Diagnóstico: ${diagnosis.term} | 
          Concepto: ${diagnosis.conceptId} | 
          ID: ${diagnosis.id}
        </strong>
      </div>
    `;

        // Update form
        const currentContent = formData.descripcion;
        const newContent = currentContent
            ? `${currentContent}${formattedDiagnosis}`
            : formattedDiagnosis;

        setFormData({
            ...formData,
            descripcion: newContent
        });

        // Clear search
        setSearchTerm('');
        setSearchResults([]);
        setShowDropdown(false);
    };

    // Helper function to update form
    const updateFormWithDiagnosis = (formattedDiagnosis) => {
        const currentContent = formData.descripcion;
        const newContent = currentContent
            ? `${currentContent}${formattedDiagnosis}`
            : formattedDiagnosis;

        setFormData({
            ...formData,
            descripcion: newContent
        });
    };

    // Helper function to clear search
    const clearSearch = () => {
        setSearchTerm('');
        setSearchResults([]);
        setShowDropdown(false);
    };

    // Function to group and sort results
    const getGroupedResults = () => {
        // Group by conceptId
        const grouped = searchResults.reduce((acc, result) => {
            if (!acc[result.conceptId]) {
                acc[result.conceptId] = [];
            }
            acc[result.conceptId].push(result);
            return acc;
        }, {});

        // Convert to array and sort by conceptId
        return Object.entries(grouped)
            .sort(([conceptIdA], [conceptIdB]) => Number(conceptIdA) - Number(conceptIdB));
    };

    return (
        <ContentWrapper title="Ingreso de diagnóstico" loading={loading} error={error}>
            <form onSubmit={handleSubmit} className="form">

                <SampleDetail selectedSample={selectedSample}>
                </SampleDetail>

                <div className="search-section">
                    <div className="search-header">
                        <div className="search-branding">
                            <img
                                src={snomedLogo}
                                alt="SNOMED CT Logo"
                                className="snomed-logo"
                            />
                            <div className="title-container">
                                <h3>Buscador SNOMED-CT</h3>
                                <div className="info-tooltip">
                                    <svg
                                        className="info-icon"
                                        viewBox="0 0 24 24"
                                        width="20"
                                        height="20"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
                                        />
                                    </svg>
                                    <span className="tooltip-text">
                      SNOMED CT es la terminología clínica integral, multilingüe y codificada de mayor amplitud, precisión e importancia desarrollada en el mundo de la salud.
                    </span>
                                </div>
                            </div>
                            <a
                                href="https://www.snomed.org/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="snomed-link"
                                title="Visitar Sitio oficial SNOMED International"
                            >
                                <svg
                                    className="external-link-icon"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/>
                                    <polyline points="15 3 21 3 21 9"/>
                                    <line x1="10" y1="14" x2="21" y2="3"/>
                                </svg>
                            </a>
                        </div>
                    </div>

                    <div className="search-container">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleInputChange}
                            placeholder="Buscar código Snomed-CT..."
                            className="search-input"
                        />
                        <button
                            type="button"
                            onClick={handleSearch}
                            className="search-button"
                            disabled={isSearching}
                        >
                            {isSearching ? 'Buscando...' : 'Buscar'}
                        </button>
                    </div>

                    {showDropdown && searchResults.length > 0 && (
                        <div className="search-dropdown">
                            {getGroupedResults().map(([conceptId, results], groupIndex) => (
                                <div
                                    key={conceptId}
                                    className={`dropdown-group ${groupIndex % 2 === 0 ? 'even-group' : 'odd-group'}`}
                                >
                                    <div className="concept-header">
                                        Concepto: {conceptId}
                                    </div>
                                    {results.map((result) => (
                                        <div
                                            key={result.id}
                                            className="dropdown-item"
                                            onClick={() => handleDiagnosisSelect(result)}
                                        >
                                            <div className="term-container">
                                                <span className="term">{result.term}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="icd11-section" style={{
                    marginBottom: '20px',
                    background: '#fff',
                    border: '1px solid #e0e0e0',
                    borderRadius: '4px',
                    padding: '20px',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                    position: 'relative'
                }}>
                    <div className="search-header" style={{
                        marginBottom: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <div className="search-branding" style={{display: 'flex', alignItems: 'center'}}>
                            <img
                                src={icdLogo}
                                alt="ICD-11 Logo"
                                style={{maxHeight: '40px'}}
                            />
                            <div className="title-container">
                                <h3>Buscador ICD-11</h3>
                                <div className="info-tooltip">
                                    <svg
                                        className="info-icon"
                                        viewBox="0 0 24 24"
                                        width="20"
                                        height="20"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
                                        />
                                    </svg>
                                    <span className="tooltip-text">
                                        ICD-11 es la undécima revisión de la Clasificación Internacional de Enfermedades
                                    </span>
                                </div>
                            </div>
                            <a
                                href="https://icd.who.int/en"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="snomed-link"
                                title="Visitar Sitio oficial ICD-11"
                                style={{marginLeft: 'auto'}}
                            >
                                <svg
                                    className="external-link-icon"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/>
                                    <polyline points="15 3 21 3 21 9"/>
                                    <line x1="10" y1="14" x2="21" y2="3"/>
                                </svg>
                            </a>
                        </div>
                    </div>

                    <div style={{
                        position: 'relative',
                        height: '500px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <input
                            type="text"
                            className="ctw-input"
                            autoComplete="off"
                            data-ctw-ino="icd11-container"
                            placeholder="Buscar código ICD-11"
                            style={{
                                width: '100%',
                                padding: '8px',
                                border: '1px solid #0066cc',
                                borderRadius: '4px',
                                outline: 'none',
                                marginBottom: '10px'
                            }}
                        />
                        <div
                            className="ctw-window"
                            data-ctw-ino="icd11-container"
                            style={{
                                flex: 1,
                                width: '100%',
                                border: '1px solid #e0e0e0',
                                borderRadius: '4px',
                                backgroundColor: '#fff',
                                overflowY: 'auto',
                                position: 'relative'
                            }}
                        ></div>
                    </div>
                </div>

                <div className="form-group">

                    <ReactQuill value={formData.descripcion} onChange={handleQuillChange}
                                placeholder="Ingresa el diagnóstico detallado..."
                                style={{height: '400px', marginBottom: '50px'}}/>
                </div>

                <button type="submit" className="submit-button">Enviar</button>
            </form>

            {showModal && (
                <Modal
                    modalLoading={modalLoading}
                    error={error}
                    onClose={() => confirmOperation()}
                />
            )}
            {showModalConfirmation && (
                <ConfirmationModal
                    message={`Confirma el ingreso de diagnóstico?`}
                    onConfirm={() => confirmDiagnosis()}
                    onCancel={() => setShowModalConfirmation(false)}
                />
            )}

        </ContentWrapper>
    );
};


const mapStateToProps = (state) => ({
    modalLoading: selectors.modalLoading(state),
    loading: selectors.loading(state),
    error: selectors.error(state),
    session: sessionSelectors.session(state),
    selectedSample: selectors.selectedSample(state),
});

export default connect(mapStateToProps)(Diagnostico);

