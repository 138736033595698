export const types = {
    SESSION_REQUEST: 'SESSION_REQUEST',
    SESSION_SUCCESS: 'SESSION_SUCCESS',
    SESSION_FAILURE: 'SESSION_FAILURE',
    UPDATE_SESSION: 'UPDATE_SESSION',
    CLEAR_SESSION: 'CLEAR_SESSION',
    SET_SOCKET : 'SET_SOCKET',
    CLEAR_SOCKET : 'CLEAR_SOCKET',
}

const initialState = {
    loading: false, error: null, data: {
        username: undefined, userType: undefined, timestamp: undefined,
    }, user: undefined,
    socket: null,
};

const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SESSION_REQUEST:
            return {
                ...state, loading: true, error: null
            };
        case types.SESSION_SUCCESS:
            return {
                ...state, loading: false, data: {
                    username: action.payload.username,
                    accessToken: action.payload.accessToken,
                    refreshToken: action.payload.refreshToken,
                    userType: action.payload.userType,
                }
            };
        case types.SESSION_FAILURE:
            return {...state, loading: false, error: action.code};
        case types.UPDATE_SESSION:
            return {
                ...state, loading: false, data: {
                    username: action.data.username,
                    userType: action.data.userType,
                    timestamp: action.data.timestamp,
                    accessToken: action.data.accessToken,
                    refreshToken: action.data.refreshToken,
                }
            };
        case types.CLEAR_SESSION:
            return initialState;

        case types.SET_SOCKET:
            return {
                ...state,
                socket: action.payload,
            };
        case types.CLEAR_SOCKET:
            return {
                ...state,
                socket: null,
            };    
        default:
            return state;
    }
};

export const getSession = ({session}) => {
    return {type: types.SESSION_REQUEST, session};
}

export const getSessionSuccess = (data) => ({
    type: types.SESSION_SUCCESS, payload: data,
});

export const getSessionFailure = (error) => ({
    type: types.SESSION_FAILURE, code: error
});

export const putSession = (data) => ({
    type: types.UPDATE_SESSION,
    data,
});

export const clearSession = () => ({
    type: types.CLEAR_SESSION,
});

export const setSocket = (socket) => ({
  type: types.SET_SOCKET,
  payload: socket,
});

export const clearSocket = () => ({
  type: types.CLEAR_SOCKET,
});

export const selectors = {
    session: state => state.session.data,
    username: state => state.session.data.username,
    socket: state => state.session.socket,
};

export default sessionReducer;


